import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addToTrending,
  createCategory,
  createTags,
  getTagsById,
  updateTags,
} from "../../util/Auth/API";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { TagsInput } from "../../Components/TagInput";
import { useEffect } from "react";

const EditTags = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [tagss, setTagss] = useState([]);
  const { id } = useParams();

  const handleSubmit = async (e) => {
    if (tagss.length === 0) return toast.error("Please enter a Tags");
    setLoading(true);
    e.preventDefault();
    try {
      await updateTags({ id: id, tags: tagss });
      toast.success("Successfully added");
      setLoading(false);
      history.push("/tags");
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const res = await getTagsById(id);
        setTagss(res.data?.data?.tags);
      } catch (error) {}
    };
    fetchTags();
  }, []);

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <TagsInput tagss={tagss} setTagss={setTagss} />
        </div>
        <div className="d-flex mb-3">
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTags;
