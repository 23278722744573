const initialState = {
    allMeal: [],
};

export const MealReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ALLMEAL":
            return {
                ...state,
                allMeal: action.payload,
            };
        case "ADDMEAL":
            return {
                ...state,
                allMeal: [...state.allMeal, action.payload],
            };
        case "UPDATEMEAL":
            const updateMeal = state.allMeal.map((meal) => {
                if (meal.id || meal._id === action.payload.id) {
                    debugger;
                    meal.number = action.payload.number;
                    meal.type = action.payload.type;
                    meal.content = action.payload.content;
                }
                return meal;
            });
            
            return {
                ...state,
                allMeal: updateMeal,
            };
        case "DELETEMEAL":
            return {
                ...state,
                allMeal: state.allMeal.filter(
                    (item) => (item.id || item._id)  !== action.payload
                ),
            };
        case "REMOVEMEAL":
            return {
                ...state,
                allMeal: state.allMeal.filter(
                    (item) => item.number   !== action.payload
                ),
            };

        default:
            return state;
    }
};
