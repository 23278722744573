import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../util/Firebase/Firebase.init";

export const uploadFile = (e, setInputFields) => {
  const file = e.target.files[0];
  if (!file) return;
  const sotrageRef = ref(storage, `files/${file.name}`);
  const uploadTask = uploadBytesResumable(sotrageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = `${Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )}%`;
    },
    (error) => console.log(error),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setInputFields((prev) => {
          return { ...prev, logo: downloadURL };
        });
      });
    }
  );
};
export const uploadFile1 = (e, setInputFields) => {
  const file = e.target.files[0];
  if (!file) return;
  const sotrageRef = ref(storage, `files/${file.name}`);
  const uploadTask = uploadBytesResumable(sotrageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = `${Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )}%`;
    },
    (error) => console.log(error),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setInputFields((prev) => {
          return { ...prev, background: downloadURL };
        });
      });
    }
  );
};
