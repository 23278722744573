import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addToTrending,
  addWatchListApi,
  getAllAuthor,
  getAllCategory,
  getAllTags,
  searchListById,
  searchListByName,
} from "../../util/Auth/API";
import noMovie from "../../../src/assets/noImg.png";
import empty from "../../../src/assets/empty.png";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { uploadFile } from "../../firebase/fileupload";
import ReactQuill from "react-quill";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "./arrayMove";

import styled from "styled-components";
const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;
const AddWatchList = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const [title, setTitle] = useState("");
  const [urlTitle, setUrlTitle] = useState("");

  const [selectData, setSelectData] = useState([]);
  const [longDescription, setLongDescription] = useState("");
  const [descption, setDescption] = useState("");
  const [show, setShow] = useState(false);
  const [orderedList, setOrderedList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [form, setform] = useState(null);
  const [tagss, setTagss] = useState([]);
  const [tagsAll, setTagsAll] = useState([]);
  const [showDescription, setShowDescription] = useState({});
  const [author, setAuthor] = useState(null);
  const [categoryAll, setCategoryAll] = useState([]);
  const [authorAll, setAuthorAll] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [image, setImage] = useState(null);
  const [previousId, setPreviousId] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const history = useHistory();
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "vertical",
    "video",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const [searchType, setSearchType] = useState("name");
  const action = (d, index) => {
    const id =
      (d.seasonNumber && d.seasonNumber) ||
      (d.movieId && d.movieId) ||
      (d.webSeriesId && d.webSeriesId);
    if (selected.includes(id)) {
      setSelected(selected.filter((i) => i !== id));
      return;
    }
    setSelected([...selected, id]);
    console.log(
      selectData.some(
        (i) => i.seasonNumber === id || i.webSeriesId === id || i.movieId === id
      )
    );
    if (
      selectData.some(
        (i) => i.seasonNumber === id || i.webSeriesId === id || i.movieId === id
      )
    ) {
      setSelectData(
        selectData.filter(
          (a) => a.seasonNumber || a.webSeriesId || a.movieId !== id
        )
      );
      return;
    }
    setSelectData([...selectData, d]);
    setPreviousId(index);
    const isMovie = d.movieId ? true : false;

    let body = {
      ...(true && { isMovie: isMovie }),
      ...(d.type === "Movie"
        ? { movieId: d.movieId }
        : { webSeriesId: d.webSeriesId }),
      ...(d.type === "Season" && { seasonNumber: d?.seasonNumber }),
      ...(true && { streaming_date: new Date(Date.now()) }),
    };
    setform(body);
  };

  const RowHandler = SortableHandle(() => (
    <Handle className="handle">||||</Handle>
  ));
  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });
  const SortableItem = SortableElement(({ d, index }) => (
    <>
      <TrWrapper className={""}>
        <td className="align-middle">
          <RowHandler />
        </td>
        <td className="image-td">
          <img
            src={
              d?.poster_path
                ? `https://image.tmdb.org/t/p/original/${d.poster_path}`
                : noMovie
            }
            alt="Poster"
          />
        </td>
        <td className="align-middle">{d.webSeriesId || d.movieId}</td>
        <td className="align-middle">{d.original_name || d.name}</td>
        <td className="align-middle">{d.type}</td>

        <td className="align-middle">
          <p
            className="text-black2"
            style={{
              color: "#000!important",
            }}
            dangerouslySetInnerHTML={{ __html: d?.description }}
          />
          <Button
            variant="success"
            onClick={() => {
              setShowDescription({
                id: Number(d.seasonNumber || d.movieId || d.webSeriesId),
                title: d.title || d.name,
                value: true,
              });
              setShow(false);
            }}
          >
            {d?.description ? "Edit" : "Add"}
          </Button>
        </td>
        <td
          className="align-middle text-danger fw-bold cursor"
          onClick={() =>
            removeItem(
              d.seasonNumber || d.movieId || d.webSeriesId,
              (d.seasonNumber && "season") ||
                (d.movieId && "movie") ||
                (d.webSeriesId && "webseries")
            )
          }
        >
          X
        </td>
      </TrWrapper>
    </>
  ));

  useEffect(() => {
    if (orderedList) {
      const orderSet = selectData?.map((i, index) => {
        return {
          ...i,
          order: index,
        };
      });
      console.log(orderSet);
      setSelectData(orderSet);
    }
  }, [orderedList]);

  useEffect(() => {
    const fetchCategory = async () => {
      const res = await getAllCategory("WATCHLIST");
      setCategoryAll(res?.data?.data);
    };
    const fetchAuthor = async () => {
      const res = await getAllAuthor("NEWS");
      setAuthorAll(res?.data?.data);
    };
    const fetchTags = async () => {
      const res = await getAllTags("WATCHLIST");
      const tagsData = res.data?.data.map((item) => item.tags);
      const unique = [...new Set(tagsData.flat())];
      const options = unique.map((i) => {
        return {
          value: String(Math.random()),
          label: i,
        };
      });
      setTagsAll(options);
    };
    fetchAuthor();
    fetchTags();
    fetchCategory();
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setSelectData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    setOrderedList((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addToTrending(form);
      toast.success("Added to trending");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectData?.length) {
      const updatedData = selectData.map((i) => {
        return {
          ...i,
          description: i?.description || "",
        };
      });
      setSelectData(updatedData);
    }
  }, [selectData?.length]);

  const changeSearchTypeHandler = (e) => {
    setSearchType(e.target.value);
    setQuery("");
  };

  const FetchData = async () => {
    setLoading(true);
    try {
      let res;
      if (searchType === "name") {
        res = await searchListByName(query);
      } else if (searchType === "id") {
        res = await searchListById(query);
      }
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const removeItem = (id, type) => {
    const newSelected = selected.filter((i) => i !== id);
    setSelected(newSelected);
    const newSelectData = selectData.filter(
      (i) =>
        (type === "season" && i.seasonNumber !== id) ||
        (type === "webseries" && i.webSeriesId !== id) ||
        (type === "movie" && i.movieId !== id)
    );
    setSelectData(newSelectData);
  };

  const addWatchList = async () => {
    const yes = window.confirm("Are you sure?");
    if (!yes) return;
    if (
      !title ||
      !category ||
      !author ||
      !selectData?.length ||
      !publishedDate
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    const movies = selectData.filter((i) => i.movieId);
    const webseries = selectData.filter(
      (i) => i.webSeriesId && !i.seasonNumber
    );
    const seasons = selectData.filter((i) => i.seasonNumber);
    try {
      const res = await addWatchListApi({
        movies: movies.map((i) => {
          return {
            movieId: i?.movieId,
            description: i?.description,
            order: i?.order || 0,
          };
        }),
        webseries: webseries.map((i) => {
          return {
            webSeriesId: i?.webSeriesId,
            description: i?.description,
            order: i?.order || 0,
          };
        }),
        title: title,
        seasons: seasons.map((i) => {
          return {
            seasonNumber: i?.seasonNumber,
            description: i?.description,
            order: i?.order || 0,
          };
        }),
        description: descption,
        seasonsList: seasons.map((i) => i),
        author: author,
        longDescription: longDescription,
        category: category,
        tags: tagss,
        thumbnail: image?.logo || "",
        lastUpdated: lastUpdated,
        publishedDate: publishedDate,
        urlTitle: urlTitle,
      });
      if (res.status === 200) {
        toast.success("Watchlist Added Successfully");
        history.push("/watch-list");
        setShow(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Title Of Watchlist"
            className="me-2"
            aria-label="Search"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Title Of Watchlist"
            className="me-2"
            aria-label="Search"
            value={urlTitle}
            onChange={(e) => setUrlTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <textarea
            type="search"
            placeholder="Enter Full Description"
            className="me-2 form-control"
            aria-label="Search"
            value={descption}
            onChange={(e) => setDescption(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <Form.Control type="file" onChange={(e) => uploadFile(e, setImage)} />
        </div>
        {image?.logo && (
          <div className="d-flex mb-3">
            <img src={image.logo} alt="logo" width="100" height="100" />
          </div>
        )}
        <div className="d-flex">
          <Form.Select
            onChange={changeSearchTypeHandler}
            style={{ width: "100px", marginRight: "10px" }}
          >
            <option value="name">Title</option>
            <option value="id">TMDB ID</option>
          </Form.Select>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            value={query}
            onKeyDown={(e) => e.key === "Enter" && FetchData(e.target.value)}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            onClick={(e) => FetchData(query)}
            style={{ backgroundColor: "#FF4F4F", border: "0", color: "white" }}
            variant="outline-success"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Search"
            )}
          </Button>
        </div>
        {data?.length ? (
          <>
            <div
              style={{
                maxHeight: "60vh",
                marginTop: "30px",
                overflow: "auto",
              }}
            >
              <Table className="bg-white" hover size="sm">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Poster</th>
                    <th>Tmdb Id</th>
                    <th>Title</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((d, index) => (
                    <tr
                      key={index}
                      onClick={() => action(d, index)}
                      style={{
                        background: selected.includes(
                          (d.seasonNumber && d.seasonNumber) ||
                            (d.movieId && d.movieId) ||
                            (d.webSeriesId && d.webSeriesId)
                        )
                          ? "#C0C0C0"
                          : "white",
                        cursor: "pointer",
                      }}
                    >
                      <td className="align-middle">{index + 1}</td>
                      <td className="image-td">
                        <img
                          src={
                            d?.poster_path
                              ? `https://image.tmdb.org/t/p/original/${d.poster_path}`
                              : noMovie
                          }
                          alt="Poster"
                        />
                      </td>
                      <td className="align-middle">
                        {d.webSeriesId || d.movieId}
                      </td>
                      <td className="align-middle">
                        {d.original_name || d.name}
                      </td>
                      <td className="align-middle">{d.type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Button
              style={{
                border: "0",
                marginTop: "20px",
              }}
              variant="success"
              onClick={() => setShow(true)}
              disabled={selected === null ? true : false}
            >
              View Selected {selected.length > 0 && `(${selected.length})`}
            </Button>
          </>
        ) : (
          data?.length === 0 && (
            <h5 className="text-center mt-5">Nothing found !!</h5>
          )
        )}
        <div className="d-flex mb-3 mt-3">
          <Form.Select onChange={(e) => setCategory(e.target.value)}>
            <option value="0">Select Category</option>
            {categoryAll?.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            className="mx-2"
            onChange={(e) => setAuthor(e.target.value)}
          >
            <option value="0">Select Author</option>
            {authorAll?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex mb-3">
          <Select
            name="access"
            width="100%"
            onChange={(e) => setTagss(e)}
            isMulti
            placeholder="Select Tags"
            options={tagsAll}
            classNamePrefix="select"
            className="w-100 text-white"
          />
        </div>
        <label className="me-2">Select Published Date</label>
        <div className="d-flex mb-3">
          <Form.Control
            type="datetime-local"
            onChange={(e) => setPublishedDate(e.target.value)}
            placeholder="Enter Date"
            className="me-2"
            aria-label="Search"
          />
        </div>
        {/* <label className="me-2">Select Last Edit Date</label>
        <div className="d-flex mb-3">
          <Form.Control
            type="datetime-local"
            onChange={(e) => setLastUpdated(e.target.value)}
            placeholder="Enter Date"
            className="me-2"
            aria-label="Search"
          />
        </div> */}
        <div className="d-flex w-100 mt-2">
          <ReactQuill
            theme="snow"
            width="100%"
            modules={modules}
            style={{
              width: "100%",
              color: "white",
              backgroundColor: "#121212",
            }}
            formats={formats}
            defaultValue={longDescription}
            onChange={(content, delta, source, editor) => {
              setLongDescription(editor.getHTML());
            }}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        size="lg"
        backdrop="static"
        style={{
          zIndex: "99999999",
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {selectData?.length === 0 ? (
            <>
              <h5 className="text-center mt-1">Empty WatchList Data !!</h5>
              <img
                src={empty}
                width={500}
                style={{ margin: "auto", display: "block" }}
                height={400}
              />
            </>
          ) : (
            <>
              <Table className="bg-white" size="sm">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Poster</th>
                    <th>Tmdb Id</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <SortableCont
                  onSortEnd={onSortEnd}
                  axis="y"
                  lockAxis="y"
                  lockToContainerEdges={true}
                  lockOffset={["30%", "50%"]}
                  helperClass="helperContainerClass"
                  useDragHandle={true}
                >
                  {selectData?.map((d, index) => (
                    <SortableItem
                      key={`item-${index}`}
                      index={index}
                      value={d}
                      d={d}
                    />
                  ))}
                </SortableCont>
              </Table>
              <div className="d-flex justify-content-end">
                {/* <Button className="btn-danger" onClick={addWatchList}>
                  Submit
                </Button> */}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showDescription?.value}
        onHide={() =>
          setShowDescription({
            value: false,
            title: "",
            id: "",
          })
        }
        style={{
          zIndex: "99999999",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Description {showDescription?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex w-100 mt-2">
            <ReactQuill
              theme="snow"
              width="100%"
              modules={modules}
              style={{
                width: "100%",
                height: "300px",
                color: "white",
                backgroundColor: "#121212",
              }}
              formats={formats}
              defaultValue={
                selectData?.filter(
                  (ele) =>
                    ele.movieId === showDescription?.id ||
                    ele.webSeriesId === showDescription?.id ||
                    ele.seasonNumber === showDescription?.id
                )[0]?.description
              }
              onChange={(content, delta, source, editor) => {
                setSelectData(
                  selectData?.filter((ele) => {
                    if (
                      ele.movieId === showDescription?.id ||
                      ele.webSeriesId === showDescription?.id ||
                      ele.seasonNumber === showDescription?.id
                    ) {
                      ele.description = editor.getHTML();
                    }
                    return ele;
                  })
                );
              }}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="btn-danger my-2"
              onClick={() => {
                setShow(true);
                setShowDescription({});
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button className="btn-danger" onClick={addWatchList}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddWatchList;
