import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import arrayMove from "./arrayMove";
import { useEffect } from "react";
import { getTrendingSeries, updateTrending } from "../../util/Auth/API";
import SeriesRow from "./SeriesRow";
import LoadingPage from "../../Components/Loading/Loading";

const MyTableWrapper = styled.div`
  padding: 10px;

  .fixed_header {
    width: 800px;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      display: block;
      width: 807px;
      overflow: auto;
      height: 400px;
      cursor: grabbing;
      background: grey;
    }

    & > thead {
      background: yellow;
      color: black;

      & > tr {
        display: block;
        //width: 793px;
      }
    }

    & > thead th,
    & > tbody td {
      padding: 5px;
      text-align: left;
      width: 200px;
      border: 1px solid #000;
    }
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <SeriesRow {...props} />);

const SeriesTable = ({ searchInput, setUpdate, setTrending, platform, setTrendingSeries }) => {
  const [items, setItems] = useState([]);
  const [laoding, setLoading] = useState(false);

  const getTrendingSeriesData = async () => {
    try {
      setLoading(true);
      const res = await getTrendingSeries();
      setItems(res?.data?.data);
      setTrendingSeries(res?.data?.data);
      setAllProperty(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTrendingSeriesData();
  }, []);

  const [allProperty, setAllProperty] = useState([]);

  const updateOrders = async (oldIndex, newIndex) => {
    const body = {
      trendingId: items[oldIndex]?._id,
      webposition: newIndex,
    };
    try {
      const res = await updateTrending(body);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
      updateOrders(oldIndex, newIndex);
    },
    [updateOrders]
  );

  return (
    <>
      {laoding ? (
        <LoadingPage />
      ) : (
        <MyTableWrapper className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Serial</th>
                <th style={{ width: "10%" }}>Poster</th>
                <th style={{ width: "10%" }}>Tmdb Id</th>
                <th style={{ width: "20%" }}>Title</th>
                <th style={{ width: "20%" }}>Tagline</th>
                <th style={{ width: "15%" }} className="text-center">
                  Select Platform
                </th>
                <th style={{ width: "10%" }} className="text-center">
                  HomePage
                </th>
                <th style={{ textAlign: "center", width: "10%" }}>Action</th>
              </tr>
            </thead>
            <SortableCont
              onSortEnd={onSortEnd}
              axis="y"
              lockAxis="y"
              lockToContainerEdges={true}
              lockOffset={["30%", "50%"]}
              helperClass="helperContainerClass"
              useDragHandle={true}
            >
              {items?.map((value, index) => (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  property={value}
                  no={index + 1}
                  setUpdate={setUpdate}
                  allProperty={allProperty}
                  setTrending={setItems}
                  setAllProperty={setAllProperty}
                  platform={platform}
                />
              ))}
            </SortableCont>
          </table>
        </MyTableWrapper>

      )}
    </>
  );
};

export default SeriesTable;
