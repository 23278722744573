import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import LoadingPage from "../../Components/Loading/Loading";
import { getPlatforms, GetTrendingMovies } from "../../util/Auth/API";
import "../../util/Style/property.css";
import FilterTable from "./FilterTable";
import HomepageTable from "./HomepageTable";
import SeriesTable from "./SeriesTable";
import MyTable from "./Table";
import { Spinner } from "react-bootstrap";
// import Table from "./TrendingMovieTable";

const TrendingMovies = () => {
  const history = useHistory();
  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [platform, setPlatforms] = useState([]);
  

      const [trendingMovies, setTrendingMovies] = useState([]);
      const [trendingSeries, setTrendingSeries] = useState([]);
      const [trendingHome, setTrendingHome] = useState([]);

  const [select, setSelect] = useState("Movies");

  const FetchPlatforms = async () => {
    try {
      const { data } = await getPlatforms();
      setPlatforms(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchPlatforms();
  }, []);



  useEffect(() => {
    if (update) {
      setUpdate(false);
      setfilterData([]);
      setsearchInput("");
    }
  }, [update]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="lg" style={{ color: "#fff" }}></Spinner>
      </div>
    );
  const movieTrend = [];
  const seriesTrend = [];
  const homeTrend = [];

  trending.forEach((element) => {
    if(element.isMovie) {
      movieTrend.push(element);
      movieTrend.sort((item) => item?.movieposition);
    } else {
      seriesTrend.push(element);
      seriesTrend.sort((item) => item?.webposition);
    }
  });

  trending.forEach((element) => {
    if (element.isTitle) {
homeTrend.push(element);
homeTrend.sort((item) => item?.position)
    } 
  });

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setsearchInput(searchWord);

    if(select === "Movies") {
      setTrendingMovies(trendingMovies?.filter((value) => {
      return (
        value?.title?.toLowerCase().includes(searchWord.toLowerCase()) ||
        value?.original_name?.toLowerCase().includes(searchWord.toLowerCase()) ||
        value?.name?.toLowerCase().includes(searchWord.toLowerCase())
      );
    }))
    }

    if(select === "Web Series") {
            setTrendingSeries(
              trendingSeries?.filter((value) => {
                return (
                  value?.title
                    ?.toLowerCase()
                    .includes(searchWord.toLowerCase()) ||
                  value?.original_name
                    ?.toLowerCase()
                    .includes(searchWord.toLowerCase()) ||
                  value?.name?.toLowerCase().includes(searchWord.toLowerCase())
                );
              })
            );
    }

    if(select === "All") {
            setTrendingHome(
              trendingHome?.filter((value) => {
                return (
                  value?.title
                    ?.toLowerCase()
                    .includes(searchWord.toLowerCase()) ||
                  value?.original_name
                    ?.toLowerCase()
                    .includes(searchWord.toLowerCase()) ||
                  value?.name?.toLowerCase().includes(searchWord.toLowerCase())
                );
              })
            );
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={handleFilter}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() => history.push("/trending/new")}
              >
                <span>Add New</span>
              </button>
            </div>
          </div>

          <div className="mt-3 filter-div">
            <button
              onClick={() => setSelect("Movies")}
              style={{ opacity: select !== "Movies" ? "0.7" : "1" }}
            >
              Movies
            </button>
            <button
              onClick={() => setSelect("Web Series")}
              style={{ opacity: select !== "Web Series" ? "0.7" : "1" }}
            >
              Web Series
            </button>
            <button
              onClick={() => setSelect("All")}
              style={{ opacity: select !== "All" ? "0.7" : "1" }}
            >
              Home Page
            </button>
          </div>
          <div className="property-tableSection">
            {select === "Movies" &&
              (searchInput.length ? (
                <FilterTable
                  data={trendingMovies}
                  setUpdate={setUpdate}
                  setTrending={setTrendingMovies}
                  platform={platform}
                />
              ) : (
                <MyTable
                  data={movieTrend}
                  setUpdate={setUpdate}
                  setTrending={setTrending}
                  platform={platform}
                  setTrendingMovies={setTrendingMovies}
                />
              ))}

            {select === "Web Series" &&
              (searchInput.length ? (
                <FilterTable
                  data={trendingSeries}
                  setUpdate={setUpdate}
                  setTrending={setTrendingSeries}
                  platform={platform}
                />
              ) : (
                <SeriesTable
                  data={seriesTrend}
                  setUpdate={setUpdate}
                  setTrending={setTrending}
                  platform={platform}
                  setTrendingSeries={setTrendingSeries}
                />
              ))}

            {select === "All" &&
              (searchInput.length ? (
                <FilterTable
                  data={trendingHome}
                  setUpdate={setUpdate}
                  setTrending={setTrendingHome}
                  platform={platform}
                />
              ) : (
                <HomepageTable
                  data={homeTrend}
                  setUpdate={setUpdate}
                  setTrending={setTrending}
                  platform={platform}
                  setTrendingHome={setTrendingHome}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TrendingMovies;
