import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { MealReducer } from "../Reducer/MealReducer";


const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    meal: MealReducer
});

export default persistReducer(persistConfig, rootReducer);
