import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import {
  deleteCategory,
  deleteTags,
  deleteTrending,
  deleteWatchList,
  getPlatforms,
  updateTrending,
  updateWatchListHomepage,
} from "../../util/Auth/API";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import DeleteModal from "../../util/Other/DeleteModal";
import { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Button, Modal } from "react-bootstrap";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">||||</Handle>
));

const SeriesRow = ({
  index,
  no,
  property,
  className,
  setUpdate,
  allProperty,
  setAllProperty,
  setTrending,
  platform,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);

  const platformHandle = async (state) => {
    const body = {
      trendingId: property._id,
      ott: state,
    };

    try {
      await updateTrending(body);
      toast.success("Updated Successfully !!");
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };
  const updateHomepage = async (state) => {
    const body = {
      id: property._id,
      value: state,
    };

    try {
      await updateWatchListHomepage(body);
      toast.success("Updated Successfully !!");
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const handleDeleteConfirm = () => {
    setdeleteModalOpen(false);
    setConfirmDelete(true);
  };
  const handleDeleteCancel = () => {
    setdeleteModalOpen(false);
  };
  const handleDeleteProperty = (e) => {
    e.preventDefault();
    setdeleteModalOpen(true);
  };

  const handleConfirmDeleteProperty = async (id) => {
    try {
      const updatedproperty = allProperty.filter((b) => b._id !== id);
      setAllProperty(updatedproperty);
      setTrending(updatedproperty);
      await deleteTags({ id });
      setUpdate(true);
      toast.success("Deleted successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    if (ConfirmDelete) {
      handleConfirmDeleteProperty(property._id);
    }
  }, [ConfirmDelete]);

  return (
    <>
      <TrWrapper className={className}>
        <td>
          <div className="firstElement">
            <RowHandler />
            {no}
          </div>
        </td>

        <td>{property?.tags.toString()}</td>
        <td>{new Date(property?.createdAt).toDateString()}</td>

        <td className="text-right">
          <div
            className="actions"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <Button
              onClick={(e) => handleDeleteProperty(e)}
              className="btn btn-secondary btn-sm"
            >
              <RemoveRedEye />
            </Button> */}
            <Link
              to={{
                pathname: `/tags/edit/${property._id}`,
                state: { data: property },
              }}
            >
              {" "}
              <Button className="btn btn-success btn-sm">
                <ModeEditIcon />{" "}
              </Button>
            </Link>
            <button
              onClick={(e) => handleDeleteProperty(e)}
              className="delete-btn btn-sm"
            >
              <DeleteIcon />{" "}
            </button>
          </div>
        </td>
        {deleteModalOpen && (
          <DeleteModal
            show={deleteModalOpen}
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
            categorytag={property.title}
          />
        )}
      </TrWrapper>
      <Modal></Modal>
    </>
  );
};

export default SeriesRow;
