import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeason, getSeries, updateSeries } from "../../util/Auth/API";
import "../../util/Style/addPropertyForm.css";

const EditSeason = () => {
  const location = useLocation();
  const data = location.state?.data;
  // const season = location.state?.data?.seasonNumber;

  const seasonCount = location.state?.data?.seasonNumber;

  const [loading, setLoading] = useState(false);

  const [webSeries, setWebSeries] = useState();

  const [inputFields, setInputFields] = useState();
  // console.log(data);
  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getSeries(location.state?.data?.webSeriesId);

        setLoading(false);
        setWebSeries(data);

        setInputFields(data?.data?.seasons[seasonCount]);
      } catch (err) {
        console.log(err);
      }
    };

    if (data) FetchData();
  }, [data]);

  if (loading) return <h5>Loading...</h5>;

  const handleInputchange = (e) => {
    const { name, value } = e.target;

    setInputFields({ ...inputFields, [name]: value });
  };

  const handlesubmit = async (e) => {
    // setspinn(true);
    e.preventDefault();

    const updatedSeasons = webSeries?.data?.seasons?.filter((f) => {
      return f.season_number !== seasonCount;
    });
    updatedSeasons?.push(inputFields);

    webSeries.data.seasons = updatedSeasons;

    const body = {
      ...webSeries,
      webSeriesId: data.webSeriesId,
    };

    try {
      await updateSeries(body);
      toast.success("Updated Successfully");
      // setspinn(false);
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
      // setspinn(false);
    }
  };
  console.log(inputFields);
  return (
    <form>
      <div className="addproperty-container">
        <div className="addproperty-personalDetails">
          <div className="addproperty-alignRow">
            <div className="meal-title">
              <Link to="/search">
                <WestOutlinedIcon />
              </Link>
              <p>Back</p>
            </div>
          </div>
          {/* 1st row */}
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Season Name{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>

              <textarea
                name="webSeriesName"
                rows="2"
                cols="10"
                defaultValue={data?.name || "web series name"}
                placeholder="Web Series Name"
                className="addproperty-inputField"
                disabled
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Season{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="name"
                defaultValue={inputFields?.name}
                placeholder="Season"
                onChange={handleInputchange}
                className="addproperty-inputField"
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Air Date{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="air_date"
                defaultValue={inputFields?.air_date}
                placeholder="Air Date"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>

            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Overwiew{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              {/* <input
                type="text"
                name="overview"
                defaultValue={inputFields?.overview}
                placeholder="Overview"
                className="addproperty-inputField"
                onChange={handleInputchange}
              /> */}
              <textarea
                name="overview"
                rows="4"
                cols="50"
                defaultValue={inputFields?.overview}
                placeholder="Overview"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Episode Count{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="episode_count"
                defaultValue={inputFields?.episode_count}
                placeholder="Episode Count"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>

            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Poster Link{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="poster_path"
                defaultValue={inputFields?.poster_path}
                placeholder="Poster Link"
                className="addproperty-inputField"
                onChange={handleInputchange}
                disabled
              />
            </div>
          </div>
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                TMDB Id{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="id"
                defaultValue={inputFields?.id}
                placeholder="tmdb id"
                className="addproperty-inputField"
                disabled
              />
            </div>
          </div>

          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditSeason;
