import React, { useMemo, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { addToTrending, createCategory } from "../../util/Auth/API";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const type = searchParams.get("type");
  const history = useHistory();

  const handleSubmit = async (e) => {
    if (!name) return toast.error("Please enter a name");
    setLoading(true);
    e.preventDefault();
    try {
      await createCategory({ name }, type);
      toast.success("Added to trending");
      setLoading(false);
      history.push("/category");
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            required
            placeholder="Enter Name Of Category"
            className="me-2 w-50"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
