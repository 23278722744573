import React from "react";
import loaderGif from "../../assets/loading.webp";
import "./Loading.css";
import { Spinner } from "react-bootstrap";

const LoadingPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-3">
      <Spinner size="lg" style={{ color: "#fff" }}></Spinner>
    </div>
  );
};

export default LoadingPage;
