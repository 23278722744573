import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addToTrending,
  createAuthor,
  createCategory,
} from "../../util/Auth/API";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const AddAuthor = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const type = searchParams.get("type");
  const handleSubmit = async (e) => {
    if (!name) return toast.error("Please enter a name");
    setLoading(true);
    e.preventDefault();
    try {
      await createAuthor({ name }, type);
      toast.success("Added");
      setLoading(false);
      history.push("/author");
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="text"
            required
            placeholder="Enter Name of Author"
            className="me-2 w-50"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAuthor;
