import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import {
  deleteTrending,
  deleteWatchList,
  getPlatforms,
  updateTrending,
  updateWatchListHomepage,
  updateWatchListOTT,
  updateWatchlistPublish,
} from "../../util/Auth/API";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import DeleteModal from "../../util/Other/DeleteModal";
import { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Button, Modal } from "react-bootstrap";
import { LanguageData } from "../../util/Healper/Healper";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">||||</Handle>
));

const SeriesRow = ({
  index,
  no,
  property,
  className,
  setUpdate,
  allProperty,
  setAllProperty,
  setTrending,
  platform,
  platforms,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);
  const platformHandle = async (state) => {
    const body = {
      id: property._id,
      ott: state,
    };

    try {
      await updateWatchListOTT(body);
      toast.success("Updated Successfully !!");
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };
  const updateHomepage = async (state) => {
    const body = {
      id: property._id,
      value: state,
    };

    try {
      await updateWatchListHomepage(body);
      toast.success("Updated Successfully !!");
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const handleDeleteConfirm = () => {
    setdeleteModalOpen(false);
    setConfirmDelete(true);
  };
  const handleDeleteCancel = () => {
    setdeleteModalOpen(false);
  };
  const handleDeleteProperty = (e) => {
    e.preventDefault();
    setdeleteModalOpen(true);
  };

  const handleConfirmDeleteProperty = async (id) => {
    try {
      const updatedproperty = allProperty.filter((b) => b._id !== id);
      setAllProperty(updatedproperty);
      setTrending(updatedproperty);
      await deleteWatchList(id);
      setUpdate(true);
      toast.success("Deleted successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    if (ConfirmDelete) {
      handleConfirmDeleteProperty(property._id);
    }
  }, [ConfirmDelete]);

  const allGenres = property?.movies
    .concat(property?.webseries)
    .concat(property?.seasonList)
    .map((m) => m.genres)
    .flat();

  const uniqueGenres = [...new Set(allGenres.map((item) => item.name))];

  const Language = property?.movies
    .concat(property?.webseries)
    .concat(property?.seasonList)
    .map((m) => m.original_language)
    .flat();

  const uniqueLanguage = [...new Set(Language.map((item) => item))];

  const Published = async (id, value) => {
    const yes = window.confirm(
      `Are you sure you want to ${value ? "publish" : "unpublish"} this news?`
    );
    if (!yes) return;
    try {
      const body = {
        id: id,
        value: value,
      };
      await updateWatchlistPublish(body);
      toast.success("Updated Successfully !!");
      window.location.reload();
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  return (
    <>
      <TrWrapper className={className}>
        <td>
          <div className="firstElement">
            <RowHandler />
            {no}
          </div>
        </td>
        <td className="image-td">
          <img
            src={`https://image.tmdb.org/t/p/original${
              property?.movies
                .concat(property?.webseries)
                .concat(property?.seasonList)[0]?.poster_path
            }`}
            alt="Poster"
          />
        </td>
        <td>{property?.title}</td>
        <td className="text-center">
          {property?.description &&
            property?.description?.substring(0, 50) + "..."}
        </td>
        <td className="text-center">
          {
            property?.movies
              .concat(property?.webseries)
              .concat(property?.seasonList)?.length
          }
        </td>
        <td className="text-center">
          <button
            onClick={() => Published(property?._id, !property?.published)}
            className={`btn ${
              property?.published ? "btn-success" : "btn-danger"
            } btn-sm`}
          >
            {property?.published ? "Published" : "Unpublished"}
          </button>
        </td>
        <td className="text-center">
          <Multiselect
            options={platforms}
            displayValue="name"
            showCheckbox={true}
            value={property?.ott}
            // selectedValues={platforms?.name}
            selectedValues={property?.ott?.map((item) => {
              return { name: item.name, _id: item?._id };
            })}
            onSelect={(e) => platformHandle(e.map((item) => item._id))}
            onRemove={(e) => platformHandle(e.map((item) => item._id))}
            // avoidHighlightFirstOption
            showArrow
            closeIcon="close"
            placeholder="Select Platform"
            style={{
              chips: {
                background: "#007bff",
              },
              searchBox: {
                border: "none",
                "border-bottom": "1px solid #007bff",
                "border-radius": "0",
              },
              multiselectContainer: {
                width: "100%",
                color: "#000",
              },
            }}
          />
        </td>
        <td className="text-center">
          {uniqueGenres?.map((genre) => (
            <span className="badge badge-primary text-black">{genre}</span>
          ))}
        </td>
        <td className="text-center">
          {/* <Multiselect
            options={platforms.map((p) => ({ name: p.name }))}
            displayValue="name"
            showCheckbox={true}
            value={platforms?.name}
            selectedValues={platforms?.name}
            onSelect={(e) => platformHandle(e)}
            onRemove={(e) => platformHandle(e)}
            avoidHighlightFirstOption
            showArrow
            closeIcon="close"
            placeholder="Select Platform"
            style={{
              chips: {
                background: "#007bff",
              },
              searchBox: {
                border: "none",
                "border-bottom": "1px solid #007bff",
                "border-radius": "0",
              },
              multiselectContainer: {
                width: "100%",
                color: "#000",
              },
            }}
          /> */}
          {uniqueLanguage?.map((language) => (
            <span className="badge badge-primary text-black">
              {LanguageData?.find((l) => l.code === language)?.name}
            </span>
          ))}
        </td>
        <td className="text-center">
          {(property?.movies?.length > 0 &&
            property?.webseries?.length > 0 &&
            property?.seasonList?.length > 0 &&
            "Movies & Webseries") ||
            (property?.movies?.length > 0 &&
              property?.webseries?.length > 0 &&
              property?.seasonList?.length === 0 &&
              "Movies & Webseries") ||
            (property?.movies?.length > 0 &&
              property?.webseries?.length === 0 &&
              "Movies") ||
            (property?.movies?.length === 0 &&
              property?.webseries?.length > 0 &&
              "Webseries") ||
            (property?.movies?.length === 0 &&
              property?.webseries?.length === 0 &&
              property?.seasonList?.length > 0 &&
              "Webseries")}
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            defaultChecked={property.homepage}
            onChange={() => updateHomepage(!property.homepage)}
          />
        </td>
        <td className="text-right">
          <div
            className="actions"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <Button
              onClick={(e) => handleDeleteProperty(e)}
              className="btn btn-secondary btn-sm"
            >
              <RemoveRedEye />
            </Button> */}
            <Link
              to={{
                pathname: `/watch-list/edit/${property._id}`,
                state: { data: property },
              }}
            >
              {" "}
              <Button className="btn btn-success btn-sm">
                <ModeEditIcon />{" "}
              </Button>
            </Link>
            <button
              onClick={(e) => handleDeleteProperty(e)}
              className="delete-btn btn-sm"
            >
              <DeleteIcon />{" "}
            </button>
          </div>
        </td>
        {deleteModalOpen && (
          <DeleteModal
            show={deleteModalOpen}
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
            categorytag={property.title}
          />
        )}
      </TrWrapper>
      <Modal></Modal>
    </>
  );
};

export default SeriesRow;
