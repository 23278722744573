import React, { useEffect, useState } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import {
  getNewReleaseReq,
  getNewTheaterReq,
  getUpcomingReleaseReq,
  getUpcomingTheater,
} from "../../util/Auth/API";
import "../../util/Style/table.css";
import WorkOuttableRow from "./UpcomingRows";

const TheaticalTable = ({
  propertyData,
  setUpdate,
  update,
  platform,
  setUpcomingRelease,
}) => {
  const [allProperty, setAllProperty] = useState([]);
  const [laoding, setLoading] = useState(false);
  const [select, setSelect] = useState("all");
  const [select2, setSelect2] = useState("new");

  const getUpcomingRelease = async () => {
    try {
      setLoading(true);
      const res = await getUpcomingTheater();
      const filteredData = res?.data?.data?.data;
      setUpcomingRelease(filteredData);
      setAllProperty(filteredData || []);
      //   setUpcomingRelease(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getNew = async () => {
    try {
      setLoading(true);
      const res = await getNewTheaterReq();
      const filteredData = res?.data?.data.data;
      setUpcomingRelease(filteredData);
      setAllProperty(filteredData);
      //   setUpcomingRelease(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (select2 === "new") {
      getNew();
    } else {
      getUpcomingRelease();
    }
  }, [select2]);
  useEffect(() => {
    if (select !== "all") {
      const filterData = propertyData?.filter(
        (item) => item?.original_language === select
      );
      setAllProperty(filterData || []);
    }
    if (select === "all") {
      setAllProperty(propertyData || []);
    }
  }, [select]);

  return (
    <>
      {laoding ? (
        <LoadingPage />
      ) : (
        <>
          <div className="mt-3 filter-div-3">
            <button
              onClick={() => setSelect2("new")}
              style={{ opacity: select2 === "new" ? "1" : "0.7" }}
            >
              New Theatrical
            </button>
            <button
              onClick={() => setSelect2("upcoming")}
              style={{ opacity: select2 === "upcoming" ? "1" : "0.7" }}
            >
              Upcoming Theatrical
            </button>
          </div>
          <div className="mt-3 filter-div-2">
            <button
              onClick={() => setSelect("all")}
              style={{ opacity: select === "all" ? "1" : "0.7" }}
            >
              All
            </button>
            <button
              onClick={() => setSelect("hi")}
              style={{ opacity: select === "hi" ? "1" : "0.7" }}
            >
              Hindi
            </button>
            <button
              onClick={() => setSelect("en")}
              style={{ opacity: select === "en" ? "1" : "0.7" }}
            >
              English
            </button>
            <button
              onClick={() => setSelect("ta")}
              style={{ opacity: select === "ta" ? "1" : "0.7" }}
            >
              Tamil
            </button>
            <button
              onClick={() => setSelect("te")}
              style={{ opacity: select === "te" ? "1" : "0.7" }}
            >
              Telugu
            </button>
            <button
              onClick={() => setSelect("kn")}
              style={{ opacity: select === "kn" ? "1" : "0.7" }}
            >
              Kannada
            </button>
          </div>
          <div className="table-wrapper" id="#scrollBar">
            <table className="fl-table">
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>Poster</th>
                  <th>Tmdb Id</th>
                  <th>Title</th>
                  <th>Tagline</th>
                  <th className="text-center">Platform</th>
                  <th className="text-center">Streaming Date</th>
                  <th style={{ textAlign: "center" }}>Date Visiblity</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {allProperty?.length > 0 &&
                  allProperty?.map((property, index) => {
                    return (
                      <WorkOuttableRow
                        key={index}
                        index={index}
                        property={property}
                        isDisplayPlatform={true}
                        setUpdate={setUpdate}
                        update={update}
                        select2={select2}
                        platform={platform}
                        getNew={getNew}
                        getUpcomingRelease={getUpcomingRelease}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default TheaticalTable;
