// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWVHrYdRQH-BMSE_r2eJkgAgr5sv0X-0c",
  authDomain: "flicksbuddy-2e4fa.firebaseapp.com",
  projectId: "flicksbuddy-2e4fa",
  storageBucket: "flicksbuddy-2e4fa.appspot.com",
  messagingSenderId: "663400102279",
  appId: "1:663400102279:web:588794bbc7992676d27b22",
  measurementId: "G-SD17574CSQ",
};

const app = initializeApp(firebaseConfig);
export default app;
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider();
export const firebaseAuth = getAuth();
