import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import NavSidebar from "./Components/NavSideBar/NavSidebar";
import BodyWorkout from "./Pages/TrendingMovies/TrendingMovies";
import LoginPage from "./Pages/Login/Login";
import AddTrending from "./Pages/TrendingMovies/AddTrending";
import Calendar from "./Pages/Calendar/Calendar";
import AddCalendar from "./Pages/Calendar/AddCalendar";
import Search from "./Pages/DatabaseEdit/Search";
import EditSeries from "./Pages/DatabaseEdit/EditSeries";
import EditMovie from "./Pages/DatabaseEdit/EditMovie";
import EditCalendar from "./Pages/Calendar/EditCalendar";
import EditTrending from "./Pages/TrendingMovies/EditTrending";
import Platforms from "./Pages/Platforms/Platforms";
import AddNew from "./Pages/Platforms/AddNew";
import EditPlatform from "./Pages/Platforms/EditPlatform";
import EditSeason from "./Pages/DatabaseEdit/EditSeason";
import AddBaner from "./Pages/masterdata/AddBaner";
import AddNewBanner from "./Pages/masterdata/AddNewBanner";
import EditBanner from "./Pages/masterdata/EditBanner";
import SubAdmin from "./Pages/SubAdmin/SubAdmin";
import CronJob from "./Pages/CronJob/CronJob";
import AddWatchList from "./Pages/Watchlist/AddWatchlist";
import WatchlistMain from "./Pages/Watchlist/WatchlistMain";
import EditWatchList from "./Pages/Watchlist/EditWatchList";
import NewsMain from "./Pages/News/NewsMain";
import AddNews from "./Pages/News/AddNews";
import EditNews from "./Pages/News/EditNews";
import CategoryMain from "./Pages/Category/CategoryMain";
import AddCategory from "./Pages/Category/AddCategory";
import EditCategory from "./Pages/Category/EditCategory";
import TagsMain from "./Pages/Tags/CategoryMain";
import AddTags from "./Pages/Tags/AddTags";
import EditTags from "./Pages/Tags/EditTags";
import AuthorMain from "./Pages/Author/AuthorMain";
import AddAuthor from "./Pages/Author/AddAuthor";
import EditAuthor from "./Pages/Author/EditAuthor";
import { Preview } from "./Pages/News/Preview";
import TheaterCalender from "./Pages/Calendar/TheaterCalender";
import OTTWiseCalender from "./Pages/Calendar/OttWiseCalender";

const LogoutHandler = () => {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear();
      }
    }
  }, [location.pathname]);

  return null;
};

export const PageRoute = () => {
  const token = localStorage.getItem("accessToken");

  return (
    <Router>
      <Toaster position="top-right" />
      <LogoutHandler />
      <Switch>
        <Route exact path="/">
          {token ? <Redirect to="/trending" /> : <LoginPage />}
        </Route>
        <NavSidebar>
          <Route exact path="/calendar">
            {token ? <Calendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/theater">
            {token ? <TheaterCalender /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/ottwise">
            {token ? <OTTWiseCalender /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/theater/new">
            {token ? <AddCalendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/ottwise/new">
            {token ? <AddCalendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/calendar/new">
            {token ? <AddCalendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/calendar/edit/:id">
            {token ? <EditCalendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/theater/edit/:id">
            {token ? <EditCalendar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/trending">
            {token ? <BodyWorkout /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/trending/new">
            {token ? <AddTrending /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/trending/edit/:id">
            {token ? <EditTrending /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/search">
            {token ? <Search /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/search/edit?type=Movie">
            {token ? <EditMovie /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/search/edit?type=WebSeries">
            {token ? <EditSeries /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/search/edit?type=Season">
            {token ? <EditSeason /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/platforms">
            {token ? <Platforms /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/masterdata">
            {token ? <AddBaner /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/banner/edit/:id">
            {token ? <EditBanner /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/addnewbanner">
            {token ? <AddNewBanner /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/platform/new">
            {token ? <AddNew /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/platform/edit">
            {token ? <EditPlatform /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/watch-list">
            {token ? <WatchlistMain /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/news">
            {token ? <NewsMain /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/news/preview/:id">
            {token ? <Preview /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/category">
            {token ? <CategoryMain /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/tags">
            {token ? <TagsMain /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/author">
            {token ? <AuthorMain /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/tags/new">
            {token ? <AddTags /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/author/new">
            {token ? <AddAuthor /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/author/edit/:id">
            {token ? <EditAuthor /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/category/new">
            {token ? <AddCategory /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/category/edit/:id">
            {token ? <EditCategory /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/tags/edit/:id">
            {token ? <EditTags /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/watch-list/new">
            {token ? <AddWatchList /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/news/new">
            {token ? <AddNews /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/watch-list/edit/:id">
            {token ? <EditWatchList /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/news/edit/:id">
            {token ? <EditNews /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/cron-jobs">
            {token ? <CronJob /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/subadmins">
            {token ? <SubAdmin /> : <Redirect to="/" />}
          </Route>
        </NavSidebar>
      </Switch>
    </Router>
  );
};
