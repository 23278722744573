import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LoadingPage from "../../Components/Loading/Loading";
import { uploadFile } from "../../firebase/fileupload";
import { addNewPlatform } from "../../util/Auth/API";

const AddNew = () => {
  const [inputFields, setInputFields] = useState({
    name: "",
    logo: "",
  });

  const handleInputChange = (e) => {
    setInputFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      ...inputFields,
    };

    try {
      await addNewPlatform(body);
      toast.success("Added Successfully");
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <form>
      <div className="addproperty-container">
        <div className="addproperty-personalDetails">
          <div className="addproperty-alignRow">
            <div className="meal-title">
              <Link to="/platforms">
                <WestOutlinedIcon />
              </Link>
              <p>Back</p>
            </div>
          </div>
          {/* 1st row */}
          <div className="d-flex flex-column align-items-center">
            <div
              className="text-center"
              style={{
                width: "5rem",
                height: "5rem",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={inputFields?.logo}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Platfrom Name{" "}
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2rem",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                type="text"
                name="name"
                value={inputFields.name}
                placeholder="Platform Name"
                className="addproperty-inputField"
                onChange={handleInputChange}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Platfrom Logo{" "}
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2rem",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                type="file"
                name="logo"
                // defaultValue={inputFields.logo}
                // value={inputFields.logo}
                placeholder="Platform Logo Link"
                className="addproperty-inputField"
                onChange={(e) => uploadFile(e, setInputFields)}
              />
            </div>
          </div>
          <div
            className="addproperty-submitDetailDivMeal"
            style={{ marginBottom: "0" }}
          >
            <button
              className="addproperty-submitDetailBtn"
              onClick={handleSubmit}
            >
              Add Platform
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNew;
