import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import LoadingPage from "../../Components/Loading/Loading";
import {
  getAllCategory,
  getAllTags,
  getPlatforms,
  GetTrendingMovies,
  getWatchList,
} from "../../util/Auth/API";
import "../../util/Style/property.css";
import FilterTable from "./FilterTable";
import HomepageTable from "./HomepageTable";
import SeriesTable from "./SeriesTable";
import MyTable from "./Table";
import { Spinner } from "react-bootstrap";
// import Table from "./TrendingMovieTable";

const TagsMain = () => {
  const history = useHistory();
  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [platform, setPlatforms] = useState([]);
  const [toggle, setToggle] = useState({
    news: true,
    watchlist: false,
  });
  const [trendingHome, setTrendingHome] = useState([]);

  const FetchPlatforms = async () => {
    try {
      const { data } = await getAllTags(toggle.news ? "NEWS" : "WATCHLIST");
      setPlatforms(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchPlatforms();
  }, [toggle.news, toggle.watchlist]);

  useEffect(() => {
    if (update) {
      setUpdate(false);
      setfilterData([]);
      setsearchInput("");
    }
  }, [update]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="lg" style={{ color: "#fff" }}></Spinner>
      </div>
    );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setsearchInput(searchWord);
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-addpropertyDiv d-flex w-50 mt-4">
            <button
              className="property-addBtn mx-2"
              style={{ backgroundColor: toggle.news ? "#ff1818" : "#6C757D" }}
              onClick={() => setToggle({ news: true, watchlist: false })}
            >
              <span>News Tags</span>
            </button>
            <button
              className="property-addBtn"
              style={{
                backgroundColor: toggle.watchlist ? "#ff1818" : "#6C757D",
              }}
              onClick={() => setToggle({ news: false, watchlist: true })}
            >
              <span>Watchlist Tags</span>
            </button>
          </div>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={handleFilter}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() =>
                  history.push(
                    `${
                      toggle.news
                        ? "/tags/new?type=NEWS"
                        : "tags/new?type=WATCHLIST"
                    }`
                  )
                }
              >
                <span>Add New</span>
              </button>
            </div>
          </div>

          <div className="property-tableSection">
            {searchInput.length ? (
              <FilterTable
                data={platform}
                setUpdate={setUpdate}
                setTrending={setTrendingHome}
                platform={platform}
              />
            ) : (
              <HomepageTable
                data={platform}
                datas={platform}
                setUpdate={setUpdate}
                setTrending={setTrending}
                platform={platform}
                setTrendingHome={setTrendingHome}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TagsMain;
