import React, { useState } from "react";

import ReactQuill from "react-quill";
import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";

import {
  AddAbout,
  addPolicy,
  addTerms,
  getAbout,
  getPolicy,
  getTerms,
} from "../../util/Auth/API";
import { useEffect } from "react";

function PolicyEditor() {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const [code, setCode] = useState();
  const [title, setTitle] = useState("");
  const [update, setUpdate] = useState({});

  const submitData = async () => {
    try {
      const data = await addPolicy({
        title: title,
        description: code,
      });
      debugger;
      toast.success("Updated Successfully");
    } catch {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const data = await getPolicy();

      setTitle(data.data.data[0]?.title);
      setCode(data.data.data[0].description);
      debugger;
    };
    fetch();
  }, []);

  return (
    <>
      <div className="property d-flex justify-content-center">
        <input
          className="form-control mb-3"
          placeholder="Title"
          style={{ width: "30%" }}
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {code && (
          <>
            <div>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                defaultValue={code}
                onChange={(content, delta, source, editor) => {
                  setCode(editor.getHTML());
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="property d-flex justify-content-center">
        <button
          className="mb-5 btn btn-danger  mt-4"
          style={{ cursor: "pointer", width: "10%" }}
          onClick={() => submitData()}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default PolicyEditor;
