import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo2 from "../../assets/favicon.png";
import logo from "../../assets/footer-logo.png";
import useStyles from "./NavbarStyle";
import "./NavSidebar.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import {
  Category,
  MovieCreation,
  Newspaper,
  Person,
  TagSharp,
  Tv,
  WatchLater,
} from "@mui/icons-material";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#fff",
  color: "white",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Montserrat !important",
  },
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& span": {
      color: "#fff",
    },
    boxSizing: "border-box",
    fontFamily: "Montserrat",
    backgroundColor: "#121212", //"#21222D"
    color: "white",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const NavSidebar = (props) => {
  // const {auth, user} = useAuth()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const role = localStorage.getItem("flickbuddyRole");
  const acccessTabs = JSON.parse(localStorage.getItem("flickbuddyAccess"));
  console.log(acccessTabs);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  // useEffect(() => {
  //     if (!auth && !user) {
  //         history.push("/");
  //     }
  // }, []);

  const handleListClick = (url) => {
    history.push(url);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {/**Dashboard */}
            </Typography>
            <div className={classes.logoutBtnDiv}>
              <button className={classes.logoutBtn} onClick={handleLogout}>
                LOG OUT
              </button>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            <div className="sidebar-logo">
              <img src={logo} width="70%" height="60px" />
            </div>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon className={classes.closeDrawer} />
            </IconButton>
          </Toolbar>
          <Divider />

          <List className={classes.listDiv}>
            {role === "Admin" ? (
              <>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/trending")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/trending")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/trending") ? (
                      <WhatshotIcon
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    ) : (
                      <WhatshotIcon
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Trending" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/calendar")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/calendar")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/calendar") ? (
                      <CalendarMonthIcon
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    ) : (
                      <CalendarMonthIcon
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="OTT Calendar" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/theater")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/theater")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/theater") ? (
                      <MovieCreation
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    ) : (
                      <MovieCreation
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Theatrical Release" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/ottwise")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/ottwise")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/ottwise") ? (
                      <Tv
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    ) : (
                      <Tv
                        style={{
                          color: "white",
                          fontSize: "1.8rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="OTT Wise" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/search")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/search")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/search") ? (
                      <EditIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <EditIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Database Edit" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/platforms")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/platforms")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/platforms") ? (
                      <PlaylistAddIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <PlaylistAddIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Set Platforms" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/masterdata")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/masterdata")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/masterdata") ? (
                      <PlaylistAddIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <PlaylistAddIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Master Data" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/cron-jobs")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/cron-jobs")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/cron-jobs") ? (
                      <AcUnitIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <AcUnitIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Cron Jobs" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/subadmins")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/subadmins")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/subadmins") ? (
                      <AdminPanelSettingsIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <AdminPanelSettingsIcon
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Sub Admins" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/watch-list")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/watch-list")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/watch-list") ? (
                      <WatchLater
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <WatchLater
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="WatchList" />
                </ListItem>{" "}
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/news")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/news")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/news") ? (
                      <Newspaper
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <Newspaper
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="News" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/category")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/category")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/category") ? (
                      <Category
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <Category
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Category" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/tags")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/tags")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/tags") ? (
                      <TagSharp
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <TagSharp
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Tags" />
                </ListItem>
                <ListItem
                  button
                  className={
                    props?.location?.pathname?.includes("/author")
                      ? classes.selectedList
                      : ""
                  }
                  onClick={() => handleListClick("/author")}
                >
                  <ListItemIcon>
                    {props?.location?.pathname?.includes("/tags") ? (
                      <Person
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    ) : (
                      <Person
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Author" />
                </ListItem>
              </>
            ) : (
              <>
                {acccessTabs?.includes("Trending") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/trending")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/trending")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/trending") ? (
                        <WhatshotIcon
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      ) : (
                        <WhatshotIcon
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Trending" />
                  </ListItem>
                )}
                {acccessTabs?.includes("OTT Calendar") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/calendar")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/calendar")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/calendar") ? (
                        <CalendarMonthIcon
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      ) : (
                        <CalendarMonthIcon
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="OTT Calendar" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Theatrical") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/theater")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/theater")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/theater") ? (
                        <MovieCreation
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      ) : (
                        <MovieCreation
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Theatical Release" />
                  </ListItem>
                )}
                {acccessTabs?.includes("OTT Wise") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/ottwise")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/ottwise")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/ottwise") ? (
                        <Tv
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      ) : (
                        <Tv
                          style={{
                            color: "white",
                            fontSize: "1.8rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="OTT Wise" />
                  </ListItem>
                )}

                {acccessTabs?.includes("Database Edit") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/search")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/search")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/search") ? (
                        <EditIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <EditIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Database Edit" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Set Platforms") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/platforms")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/platforms")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/platforms") ? (
                        <PlaylistAddIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <PlaylistAddIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Set Platforms" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Master Data") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/masterdata")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/masterdata")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/masterdata") ? (
                        <PlaylistAddIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <PlaylistAddIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Master Data" />
                  </ListItem>
                )}
                {/* {acccessTabs?.includes("Cron Jobs") && ( */}
                {acccessTabs?.includes("Cron Jobs") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/cron-jobs")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/cron-jobs")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/cron-jobs") ? (
                        <AcUnitIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <AcUnitIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Cron Jobs" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Sub Admins") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/subadmins")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/subadmins")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/subadmins") ? (
                        <AdminPanelSettingsIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <AdminPanelSettingsIcon
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Sub Admins" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Watch List") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/watch-list")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/watch-list")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/watch-list") ? (
                        <WatchLater
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <WatchLater
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="WatchList" />
                  </ListItem>
                )}
                {acccessTabs?.includes("News") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/news")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/news")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/news") ? (
                        <Newspaper
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <Newspaper
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="News" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Category") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/category")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/category")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/category") ? (
                        <Category
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <Category
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Category" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Tags") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/tags")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/tags")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/tags") ? (
                        <TagSharp
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <TagSharp
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Tags" />
                  </ListItem>
                )}
                {acccessTabs?.includes("Author") && (
                  <ListItem
                    button
                    className={
                      props?.location?.pathname?.includes("/author")
                        ? classes.selectedList
                        : ""
                    }
                    onClick={() => handleListClick("/author")}
                  >
                    <ListItemIcon>
                      {props?.location?.pathname?.includes("/author") ? (
                        <Person
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      ) : (
                        <Person
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Author" />
                  </ListItem>
                )}
              </>
            )}
          </List>
        </Drawer>
        <Box
          component="main"
          className="dashboard-outlet"
          sx={{
            backgroundColor: (theme) =>
              props?.location?.pathname.includes("/add")
                ? "#fff"
                : theme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NavSidebar;
