import React, { useEffect, useState } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import { getNewReleaseReq, getUpcomingReleaseReq } from "../../util/Auth/API";
import "../../util/Style/table.css";
import WorkOuttableRow from "./UpcomingRows";

const OTTWiseTable = ({
  propertyData,
  setUpdate,
  update,
  platform,
  setUpcomingRelease,
}) => {
  const [allProperty, setAllProperty] = useState([]);
  const [laoding, setLoading] = useState(false);
  const [select, setSelect] = useState("all");
  const [select2, setSelect2] = useState("new");

  const getUpcomingRelease = async () => {
    try {
      setLoading(true);
      const res = await getUpcomingReleaseReq();
      const filteredData = res?.data?.data.filter((item) =>
        item?.ott?.some(
          (i) =>
            i?.name?.toLowerCase()?.includes("netflix") ||
            i?.name?.toLowerCase()?.includes("amazon") ||
            i?.name?.toLowerCase()?.includes("hotstar")
        )
      );
      setUpcomingRelease(filteredData);
      setAllProperty(filteredData);
      //   setUpcomingRelease(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getNew = async () => {
    try {
      setLoading(true);
      const res = await getNewReleaseReq();
      const filteredData = res?.data?.data.filter((item) =>
        item?.ott?.some(
          (i) =>
            i?.name?.toLowerCase()?.includes("netflix") ||
            i?.name?.toLowerCase()?.includes("amazon") ||
            i?.name?.toLowerCase()?.includes("hotstar")
        )
      );
      setUpcomingRelease(filteredData);
      setAllProperty(filteredData);
      //   setUpcomingRelease(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (select2 === "new") {
      getNew();
    } else {
      getUpcomingRelease();
    }
  }, [select2]);
  useEffect(() => {
    if (select !== "all") {
      const filterData = propertyData?.filter((item) =>
        item?.ott?.some((i) => i?.name?.toLowerCase()?.includes(select))
      );
      setAllProperty(filterData);
    }
    if (select === "all") {
      setAllProperty(propertyData);
    }
  }, [select]);

  return (
    <>
      {laoding ? (
        <LoadingPage />
      ) : (
        <>
          <div className="mt-3 filter-div-3">
            <button
              onClick={() => setSelect2("new")}
              style={{ opacity: select2 === "new" ? "1" : "0.7" }}
            >
              New Release
            </button>
            <button
              onClick={() => setSelect2("upcoming")}
              style={{ opacity: select2 === "upcoming" ? "1" : "0.7" }}
            >
              Upcoming Release
            </button>
          </div>
          <div className="mt-3 filter-div-2">
            <button
              onClick={() => setSelect("all")}
              style={{ opacity: select === "all" ? "1" : "0.7" }}
            >
              All
            </button>
            <button
              onClick={() => setSelect("netflix")}
              style={{ opacity: select === "netflix" ? "1" : "0.7" }}
            >
              Netflix
            </button>
            <button
              onClick={() => setSelect("amazon")}
              style={{ opacity: select === "amazon" ? "1" : "0.7" }}
            >
              Amazon Prime Video
            </button>
            <button
              onClick={() => setSelect("hotstar")}
              style={{ opacity: select === "hotstar" ? "1" : "0.7" }}
            >
              Disney Hotstar
            </button>
          </div>
          <div className="table-wrapper" id="#scrollBar">
            <table className="fl-table">
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>Poster</th>
                  <th>Tmdb Id</th>
                  <th>Title</th>
                  <th>Tagline</th>
                  <th className="text-center">Select Platform</th>
                  <th className="text-center">Streaming Date</th>
                  <th style={{ textAlign: "center" }}>Date Visiblity</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {allProperty &&
                  allProperty.map((property, index) => {
                    return (
                      <WorkOuttableRow
                        key={index}
                        index={index}
                        property={property}
                        setUpdate={setUpdate}
                        update={update}
                        platform={platform}
                        getUpcomingRelease={getUpcomingRelease}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default OTTWiseTable;
