import { PageRoute } from "./Router";

function App() {
  
  


  return <PageRoute/>
}

export default App;
