import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getBanner } from "../../util/Auth/API";
import LoadingPage from "../../Components/Loading/Loading";
import Editor from "./Editor";
import EditBanner from "./EditBanner";
import TermsEditor from "./TermsEditor";
import PolicyEditor from "./PolicyEditor";
import './AddBanner.css'

function AddBaner() {
  const [select, setSelect] = useState("add");
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState();

  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getBanner();

        setLoading(false);
        setBannerData(data?.data);
        debugger;
      } catch (err) {
        console.log(err);
      }
    };

    FetchData();
  }, []);

  return (
    <div className="banner-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <div className="filter-div">
            <button
              onClick={() => setSelect("add")}
              style={{ opacity: select !== "add" ? "0.7" : "1" }}
            >
              Add Baner
            </button>
            <button
              onClick={() => setSelect("about")}
              style={{ opacity: select !== "about" ? "0.7" : "1" }}
            >
              Add About
            </button>
            <button
              onClick={() => setSelect("terms")}
              style={{ opacity: select !== "terms" ? "0.7" : "1" }}
            >
              Add Terms
            </button>
            <button
              onClick={() => setSelect("policy")}
              style={{ opacity: select !== "policy" ? "0.7" : "1" }}
            >
              Add Policy
            </button>
          </div>

          <div className="property-tableSection">
            {select === "add" && (
              <>
                {bannerData?.length > 0 && <EditBanner data={bannerData[0]} />}
              </>
            )}
            {select === "about" && (
              <div className="my-5">
                <Editor />
              </div>
            )}
            {select === "terms" && (
              <div className="my-5">
                <TermsEditor />
              </div>
            )}
            {select === "policy" && (
              <div className="my-5">
                <PolicyEditor />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBaner;
