import React from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import LoadingPage from '../../Components/Loading/Loading';
import CronModal from './CronModal';
import "./CronJob.css";
import { useEffect } from 'react';
import { getCronsReq, updateSeriesCronsReq, updateMoviesCronsReq } from '../../util/Auth/API';


const CronJob = () => {
    const [loading, setLoading] = useState(false);
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const [allCrons, setAllCrons] = useState([]);


      const getAllCrons = async() => {
        try {
          setLoading(true);
          const res = await getCronsReq();
          if(res?.data?.success) {
            setAllCrons(res?.data?.data);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }

      useEffect(() => {
        getAllCrons();
      }, [])
  return (
    <div className="cronJob-container">
      <div>
        <div className="cronJob-topSection">
          <h4>Cron Job</h4>
          <button onClick={handleShow} className="cronJob-button">
            <span>Update</span>
          </button>
          <CronModal
            show={show}
            handleClose={handleClose}
            getAllCrons={getAllCrons}
          />
        </div>

        {loading ? (
          <LoadingPage />
        ) : (
          <Table className="cronJob-tableContainer" bordered>
            <thead className="cronJob-tableHeader">
              <tr className="cronJob-tableHeader">
                <th>S. No.</th>
                <th>Date</th>
                {/* <th>Length</th> */}
                <th>Total Updated</th>
              </tr>
            </thead>
            <tbody className="cronJob-tableBody">
              {allCrons?.map((item, index) => {
                return (
                  <tr className="cronJob-tableRow">
                    <td>{index + 1}</td>
                    <td>{item?.date}</td>
                    {/* <td>{item?.length}</td> */}
                    <td>{item?.todayData}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
}

export default CronJob;