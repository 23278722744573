import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import {
  deleteTrending,
  getPlatforms,
  schedulNews,
  updateNewsHomepage,
  updateNewsPublish,
  updateTrending,
} from "../../util/Auth/API";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import DeleteModal from "../../util/Other/DeleteModal";
import { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Button, Modal } from "react-bootstrap";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">||||</Handle>
));

const SeriesRow = ({
  index,
  no,
  property,
  className,
  setUpdate,
  allProperty,
  setAllProperty,
  setTrending,
  platform,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [schedulDate, setScheduleDate] = useState("");

  const platformHandle = async (state) => {
    const body = {
      trendingId: property._id,
      ott: state,
    };

    try {
      await updateNewsHomepage(body);
      toast.success("Updated Successfully !!");
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };
  const updateHomepage = async (state) => {
    const body = {
      id: property._id,
      value: state,
    };

    try {
      await updateNewsHomepage(body);
      toast.success("Updated Successfully !!");
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const handleDeleteConfirm = () => {
    setdeleteModalOpen(false);
    setConfirmDelete(true);
  };
  const handleDeleteCancel = () => {
    setdeleteModalOpen(false);
  };
  const handleDeleteProperty = (e) => {
    e.preventDefault();
    setdeleteModalOpen(true);
  };

  const handleConfirmDeleteProperty = async (id) => {
    try {
      const updatedproperty = allProperty.filter((b) => b._id !== id);
      setAllProperty(updatedproperty);
      setTrending(updatedproperty);
      await deleteTrending({
        trendingId: id,
      });
      setUpdate(true);
      toast.success("Deleted successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    if (ConfirmDelete) {
      handleConfirmDeleteProperty(property._id);
    }
  }, [ConfirmDelete]);

  const Published = async (id, value) => {
    const yes = window.confirm(
      `Are you sure you want to ${value ? "publish" : "unpublish"} this news?`
    );
    if (!yes) return;
    try {
      const body = {
        id: id,
        value: value,
      };
      await updateNewsPublish(body);
      toast.success("Updated Successfully !!");
      window.location.reload();
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const schedule = async (id) => {
    const yes = window.confirm(`Are you sure you want to schedule this news?`);
    if (!yes) return;
    try {
      if (!schedulDate) return toast.error("Please select date");
      const body = {
        id: id,
        date: schedulDate,
      };
      await schedulNews(body);
      toast.success("Updated Successfully !!");
      setShow(false);
      // window.location.reload();
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const descption = property?.metaDescription || property?.description;
  var date2 =
    property?.lastScheduleDate && new Date(property?.lastScheduleDate); // Or the date you'd like converted.
  const dat =
    (date2 &&
      new Date(
        date2.getTime() - date2.getTimezoneOffset() * 60000
      ).toISOString()) ||
    "";

  return (
    <>
      <TrWrapper className={className}>
        <td>
          <div className="firstElement">
            <RowHandler />
            {no}
          </div>
        </td>
        <td className="image-td">
          <img src={property?.image} alt="Poster" />
        </td>
        <td>{property?.title}</td>
        <td className="text-center">{descption?.substring(0, 50) + "..."}</td>
        <td className="text-center">
          {property?.publishedDate &&
            new Date(property?.publishedDate).toDateString()}
        </td>
        <td className="text-center">
          <button
            onClick={() => setShow(true)}
            className={`btn btn-secondary btn-sm`}
          >
            Schedule{" "}
          </button>
          {property?.lastScheduleDate &&
            new Date(
              new Date(property?.lastScheduleDate)?.toISOString()
            )?.toLocaleDateString() +
              " " +
              dat?.substring(11, 16)}
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            defaultChecked={property?.homepage}
            onChange={() => updateHomepage(!property?.homepage)}
          />
        </td>

        <td className="text-center">
          <button
            onClick={() => Published(property?._id, !property?.isPublished)}
            className={`btn ${
              property?.isPublished ? "btn-success" : "btn-danger"
            } btn-sm`}
          >
            {property?.isPublished ? "Published" : "Unpublished"}
          </button>
        </td>
        <td className="text-right">
          <div
            className="actions"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Link
              to={{
                pathname: `/news/edit/${property._id}`,
                state: { data: property },
              }}
            >
              {" "}
              <Button className="btn btn-success btn-sm mx-2">
                <ModeEditIcon />{" "}
              </Button>
            </Link>
            <Link
              to={{
                pathname: `/news/preview/${property._id}`,
                state: { data: property },
              }}
            >
              {" "}
              <Button className="btn btn-secondary btn-sm mx-2">
                <RemoveRedEye />{" "}
              </Button>
            </Link>
            <button
              onClick={(e) => handleDeleteProperty(e)}
              className="delete-btn btn-sm"
            >
              <DeleteIcon />{" "}
            </button>
          </div>
        </td>
        {deleteModalOpen && (
          <DeleteModal
            show={deleteModalOpen}
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
            categorytag={property.title}
          />
        )}
      </TrWrapper>
      <Modal
        onHide={() => setShow(false)}
        show={show}
        size="sm"
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="schedule">Schedule</label>
          <input
            type="datetime-local"
            name="schedule"
            id="schedule"
            onChange={(e) => setScheduleDate(e.target.value)}
            className="form-control"
          />
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-success mt-3"
              onClick={() => schedule(property?._id)}
            >
              Schedule
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SeriesRow;
