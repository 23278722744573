import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import { addBanner } from "../../util/Auth/API";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { uploadFile1 } from "../../firebase/fileupload";
import './AddBanner.css';

function EditBanner({ data }) {
  const history = useHistory();

  console.log(data);
  const [inputFields, setInputFields] = useState();
  console.log(inputFields);
  const [text, setText] = useState();
  useEffect(() => {
    if (data) {
      setInputFields({ background: data?.background });
      setText(data?.text);
    }
  }, [data]);

  const handleInputchange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  console.log(inputFields);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      background: inputFields?.background,
      text: text,
      // id: inputFields?._id,
    };

    try {
      await addBanner(body);
      toast.success("Updated Successfully");

      // setspinn(false);
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
      // setspinn(false);
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  return (
      <form>
        <div className="editBanner-container">
          <div className="editBanner-details">
            {/* 1st row */}
            <div className="d-flex flex-column align-items-center">
              <div
                className="text-center"
                style={{
                  width: "5rem",
                  height: "5rem",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={inputFields?.background}
                  alt=""
                />
              </div>
              <div className="">
              <div className="w-100 mb-3">
                <label className="addproperty-inputLabel ">
                  Background
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
                </label>
                <input
                  type="file"
                  name="background"
                  // defaultValue={inputFields?.background}
                  placeholder="background url"
                  className="addproperty-inputField"
                  onChange={(e) => uploadFile1(e, setInputFields)}
                />
              </div>
                <label className="addproperty-inputLabel ">
                  Text{" "}
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
                </label>
                <div className="d-flex justify-content-center">
                  {text && (
                    <ReactQuill
                      // style={{ height: "200px", width: "450px" }}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      defaultValue={text}
                      onChange={(content, delta, source, editor) => {
                        setText(editor.getHTML());
                      }}
                    />
                  )}
                </div>
              </div>

            </div>

            <div className="addproperty-submitDetailDiv">
              <button
                className="addproperty-submitDetailBtn"
                onClick={(e) => handleSubmit(e)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
  );
}

export default EditBanner;
