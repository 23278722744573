import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Button, Form, Row, Col } from "react-bootstrap";
import { addSubAdmin, editSubAdmin } from "../../util/Auth/API";
import { useEffect } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import { toast } from "react-hot-toast";

const SubAdminEdit = (props) => {
  const {
    show,
    handleClose,
    editedData,
    setEditedData,
    allSubAdmins,
    setAllSubAdmins,
  } = props;
  const [laoding, setLoading] = useState(false);
  const [formData, setFormData] = useState(editedData);
  const options = [
    {
      value: String(Math.random()),
      label: "Trending",
    },
    {
      value: String(Math.random()),
      label: "OTT Calendar",
    },
    {
      value: String(Math.random()),
      label: "Database Edit",
    },
    {
      value: String(Math.random()),
      label: "Set Platforms",
    },
    {
      value: String(Math.random()),
      label: "Master Data",
    },
    {
      value: String(Math.random()),
      label: "Cron Jobs",
    },
    {
      value: String(Math.random()),
      label: "Sub Admins",
    },
    {
      value: String(Math.random()),
      label: "Watch List",
    },
    {
      value: String(Math.random()),
      label: "Theatrical",
    },
    {
      value: String(Math.random()),
      label: "OTT Wise",
    },
    {
      value: String(Math.random()),
      label: "Tags",
    },
    {
      value: String(Math.random()),
      label: "News",
    },
    {
      value: String(Math.random()),
      label: "Category",
    },
    {
      value: String(Math.random()),
      label: "Author",
    },
  ];

  const handleChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleChangeTabs = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        access: e,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: formData?._id,
      email: formData?.email,
      password: formData?.password,
      name: formData?.name,
      username: formData?.username,
      access: formData?.access,
    };
    try {
      setLoading(true);
      const res = await editSubAdmin(data);
      console.log(res);
      if (res?.data?.success) {
        setAllSubAdmins([
          ...allSubAdmins?.filter((item) => item?._id !== editedData?._id),
          res?.data?.data,
        ]);
        handleClose();
        toast.success("Edit Success");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    setFormData(editedData);
  }, [editedData]);

  return (
    <Modal
      className="subAdmin-modal"
      centered
      show={show}
      onHide={() => {
        handleClose();
        setEditedData(null);
      }}
    >
      <Modal.Header className="subAdmin-modal-header" closeButton>
        <Modal.Title>Add Sub Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body className="subAdmin-modal-body">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col className="col-6 mb-3">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="text"
                placeholder="Enter Name"
                name="name"
                required
                onChange={handleChange}
                defaultValue={formData?.name}
              />
            </Col>
            <Col className="col-6 mb-3">
              <Form.Label>Username*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="text"
                placeholder="Enter Username"
                name="username"
                required
                onChange={handleChange}
                defaultValue={formData?.username}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-6 mb-3">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="email"
                placeholder="Enter Email"
                name="email"
                required
                onChange={handleChange}
                defaultValue={formData?.email}
              />
            </Col>
            <Col className="col-6 mb-3">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="password"
                placeholder="Enter Password"
                name="password"
                required
                onChange={handleChange}
                defaultValue={formData?.password}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Form.Label>Tab Access*</Form.Label>
              <Select
                name="access"
                onChange={handleChangeTabs}
                isMulti
                required
                placeholder="Select Tabs"
                className="basic-multi-select"
                classNamePrefix="select"
                options={options}
                defaultValue={editedData?.access}
              />
            </Col>
          </Row>
          <button type="Submit" className="subAdmin-button">
            {laoding ? <LoadingPage /> : <span>Edit</span>}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SubAdminEdit;
