import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import DeleteModal from "../../util/Other/DeleteModal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Multiselect from "multiselect-react-dropdown";
import {
  DeleteBodyWorkoutById,
  deleteCalendar,
  deleteTheater,
  updateCalendar,
  updateTrending,
} from "../../util/Auth/API";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const UpcomingRows = ({
  property,
  index,
  setUpdate,
  update,
  isDisplayPlatform,
  getNew,
  select2,
  platform,
  getUpcomingRelease,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);
  console.log(property);
  const handleDeleteConfirm = () => {
    setdeleteModalOpen(false);
    setConfirmDelete(true);
  };
  const handleDeleteCancel = () => {
    setdeleteModalOpen(false);
  };
  const handleDeleteProperty = (e) => {
    e.preventDefault();
    setdeleteModalOpen(true);
  };

  const handleConfirmDeleteProperty = async (id) => {
    try {
      if (isDisplayPlatform) {
        await deleteTheater({
          theatricalId: id,
        });
        if (select2 === "new") getNew();
        else getUpcomingRelease();
      } else {
        await deleteCalendar({
          calenderId: id,
        });
        setUpdate(!update);
        getUpcomingRelease();
      }

      toast.success("Deleted successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    if (ConfirmDelete) {
      handleConfirmDeleteProperty(property._id);
    }
  }, [ConfirmDelete]);

  const platformHandle = async (state) => {
    const body = {
      calenderId: property._id,
      ott: state,
    };
    debugger;
    try {
      await updateCalendar(body);
      toast.success("Updated Successfully !!");
      setUpdate(!update);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const handleDateVisibility = async (value) => {
    let body = {
      calenderId: property._id,
      onlyYear: value === "onlyYear",
      monthYear: value === "monthYear",
    };
    try {
      await updateCalendar(body);
      toast.success("Updated Successfully !!");
      setUpdate(!update);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const checkDate = () => {
    const date1 = new Date(Date.now());
    const date2 = new Date(property.streaming_date);

    // console.log("date1: ", date1.getFullYear(), date1.getMonth(), date1.getDate())
    console.log("date2: ", date2);

    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    ) {
      return "Today";
    } else if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() - 1 === date2.getDate()
    ) {
      return "Yesterday";
    } else if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() + 1 === date2.getDate()
    ) {
      return "Tomorrow";
    } else {
      return `${new Date(property.streaming_date).getDate()}${" "}${
        months[new Date(property.streaming_date).getMonth()]
      }${" "}${new Date(property.streaming_date).getFullYear()}`;
    }
  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td className="image-td">
          <img
            src={`https://image.tmdb.org/t/p/original/${property.poster_path}`}
            alt="Poster"
          />
        </td>
        <td>{property.movieId || property.webSeriesId}</td>
        <td>{property?.name || property?.title}</td>
        <td>{property.tagline}</td>
        <td>
          <p
            dangerouslySetInnerHTML={{
              __html:
                property?.description?.length > 100
                  ? property?.description?.slice(0, 100) + "..."
                  : property?.description,
            }}
          ></p>
        </td>
        <td className="text-center">
          {isDisplayPlatform === true && "In Theaters"}
          {!isDisplayPlatform && (
            <Multiselect
              style={{ border: "0" }}
              multiple
              value={property.ott || "select"}
              options={platform}
              displayValue="name"
              showCheckbox={true}
              selectedValues={property?.ott?.map((item) => {
                return { name: item?.name, _id: item?._id };
              })}
              onSelect={(e) => platformHandle(e.map((item) => item._id))}
              onRemove={(e) => platformHandle(e.map((item) => item._id))}
            ></Multiselect>
          )}
        </td>
        <td className="text-center">
          {checkDate()}
          {/* {new Date(property.streaming_date).getDate()}{" "}
          {months[new Date(property.streaming_date).getMonth()]}{" "}
          {new Date(property.streaming_date).getFullYear()} */}
        </td>
        <td onChange={(e) => handleDateVisibility(e.target.value)}>
          <div className="d-flex align-items-center gap-3">
            <input
              type="radio"
              id="none"
              name={index + 1}
              value="none"
              checked={!property.onlyYear && !property.monthYear}
            />
            <label for="none">Show Full Date</label>
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="radio"
              id="onlyYear"
              name={index + 1}
              value="onlyYear"
              checked={property.onlyYear}
            />
            <label for="onlyYear">Only Year</label>
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="radio"
              id="monthYear"
              name={index + 1}
              value="monthYear"
              checked={property.monthYear}
            />
            <label for="monthYear">Both year & month</label>
          </div>
        </td>
        <td className="text-right">
          <div
            className="actions"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <button
                onClick={() => handleView(property._id)}
                className="edit-btn"
              >
                <VisibilityIcon />{" "}
              </button> */}
            <Link
              to={{
                pathname: isDisplayPlatform
                  ? `/theater/edit/${property._id}`
                  : `/calendar/edit/${property._id}`,
                state: { data: property },
              }}
            >
              {" "}
              <button className="edit-btn">
                <ModeEditIcon />{" "}
              </button>
            </Link>
            <button
              onClick={(e) => handleDeleteProperty(e)}
              className="delete-btn"
            >
              <DeleteIcon />{" "}
            </button>
          </div>
        </td>
        {deleteModalOpen && (
          <DeleteModal
            show={deleteModalOpen}
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
            categorytag={property.title}
          />
        )}
        {/* <VideoModal
            show={showVideo}
            handleCancel={seeVideoCancel}
            videoLink={videoLink}
          />
          <ViewFullWorkOut
            show={viewFullWorkOut}
            handleCancel={handleViewWorkOutCancel}
            fullBodyWorkOutData={fullBodyWorkOutData}
          /> */}
      </tr>
    </>
  );
};

export default UpcomingRows;
