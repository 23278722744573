import React, { useEffect, useState } from "react";
import { getNewReleaseReq } from "../../util/Auth/API";
import "../../util/Style/table.css";
import WorkOuttableRow from "./CalendarRows";

const FilterCalTable = ({
  propertyData,
  setUpdate,
  update,
  platform,
  setNewRelease,
  isOTTShow,
}) => {
  const [allProperty, setAllProperty] = useState(propertyData);

  useEffect(() => {
    setAllProperty(propertyData);
  }, [propertyData]);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>Serial</th>
            <th>Poster</th>
            <th>Tmdb Id</th>
            <th>Title</th>
            <th>Tagline</th>

            {isOTTShow !== false && (
              <th className="text-center">Select Platform</th>
            )}
            <th className="text-center">Streaming Date</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {allProperty &&
            allProperty.map((property, index) => {
              return (
                <WorkOuttableRow
                  key={index}
                  index={index}
                  property={property}
                  isOTTShow={isOTTShow}
                  setUpdate={setUpdate}
                  update={update}
                  platform={platform}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default FilterCalTable;
