import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../assets/footer-logo.png";
import LoadingPage from "../../Components/Loading/Loading";
import { loginAdmin } from "../../util/Auth/API";
import "./Login.css";

const initialData = {
  email: "",
  password: "",
};

const LoginPage = () => {
  //   const history = useHistory();
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let access = [];
    if (formData.email && formData.password) {
      try {
        const loginInfo = {
          email: formData.email,
          password: formData.password,
        };
        const res = await loginAdmin(loginInfo);
        if (res?.data?.success) {
          localStorage.setItem("accessToken", res?.data?.data?.token);
          localStorage.setItem("flickbuddyRole", res?.data?.data?.user?.role);
          debugger;
          res?.data?.data?.user?.access?.forEach((item) => {
            access.push(item?.label);
          });
          localStorage.setItem("flickbuddyAccess", JSON.stringify(access));
          toast.success("Login successful");
          window.location.replace("/trending");
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
      }
    } else {
      toast.error("Both fields required");
    }
  };

  return (
    <div className="loginPage-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="loginPage-formDiv">
          <div className="left">
            <img src={logo} alt="logo" />
            {/* <p>
                          we provide features and interaction that helps you
                          analyse and track your data at every point of your
                          fitness journey
                      </p> */}
          </div>
          <div className="right">
            <div className="loginPage-formHeaderDiv">
              <div className="logoContainer">
                {/* <img src={logo} alt="logo" className="logoImage" /> */}
              </div>
              <div className="loginPage-headerContent">
                <h3 className="loginPage-headerTitle">Log in to Admin </h3>
                <p className="loginPage-headerSub">
                  Enter your email and password below
                </p>
              </div>
            </div>
            <form onSubmit={handleLogin} className="loginPage-formContent">
              <div className="loginPage-formFieldDiv">
                <input
                  type="email"
                  name="email"
                  className="loginPage-inputField"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="loginPage-formFieldDiv">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={formData.password}
                  className="loginPage-inputField"
                  required
                />
              </div>
              <div className="loginPage-submitBtnDiv">
                <button className="loginPage-submitBtn" type="submit">
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
