import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { searchListById, searchListByName } from "../../util/Auth/API";
import Spinner from "react-bootstrap/Spinner";
import noMovie from "../../../src/assets/noImg.png";

const Search = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [type, setType] = useState(null);
  const [form, setform] = useState(null);
    const [searchType, setSearchType] = useState("name");

  const [loading, setLoading] = useState(false);
  console.log(data);

  const action = (d, index) => {
    setSelected(index);
    console.log(d);
    const isMovie = d.type;
    setType(d);

    let body = {
      ...(true && { isMovie: isMovie }),

      ...(isMovie ? { movieId: d.movieId } : { webSeriesId: d.webSeriesId }),
      ...(true && { streaming_date: new Date(Date.now()) }),
    };

    setform(body);
  };

      const changeSearchTypeHandler = (e) => {
        setSearchType(e.target.value);
        setQuery("");
      };

  const FetchData = async () => {
    setLoading(true);
    try {
                  let res;
                  if (searchType === "name") {
                    res = await searchListByName(query);
                  } else if (searchType === "id") {
                    res = await searchListById(query);
                  }
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex">
          <Form.Select
            onChange={changeSearchTypeHandler}
            style={{ width: "100px", marginRight: "10px" }}
          >
            <option value="name">Title</option>
            <option value="id">TMDB ID</option>
          </Form.Select>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            onClick={() => FetchData(query)}
            style={{ backgroundColor: "#FF4F4F", border: "0", color: "white" }}
            variant="outline-success"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Search"
            )}
          </Button>
        </div>

        {data === null ? (
          ""
        ) : data.length !== 0 ? (
          <>
            <div
              style={{
                maxHeight: "60vh",
                marginTop: "30px",
                overflow: "auto",
              }}
            >
              <Table className="bg-white" hover size="sm">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Poster</th>
                    <th>Tmdb Id</th>
                    <th>Title</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, index) => (
                    <tr
                      key={index}
                      onClick={() => action(d, index)}
                      style={{
                        background: selected === index ? "#C0C0C0" : "white",
                        cursor: "pointer",
                      }}
                    >
                      <td className="align-middle">{index + 1}</td>
                      <td className="image-td">
                        <img
                          src={
                            d?.poster_path
                              ? `https://image.tmdb.org/t/p/original/${d.poster_path}`
                              : noMovie
                          }
                          alt="Poster"
                        />
                      </td>
                      <td className="align-middle">
                        {d.webSeriesId || d.movieId}
                      </td>
                      <td className="align-middle">
                        {d.original_name || d.name}
                      </td>
                      <td className="align-middle">{d.type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Link
              to={{
                pathname: `/search/edit?type=${type?.type}`,
                state: { data: type },
              }}
            >
              <Button
                style={{
                  backgroundColor: "#FF4F4F",
                  border: "0",
                  marginTop: "20px",
                }}
                variant="primary"
                disabled={selected === null ? true : false}
              >
                Edit Selected
              </Button>
            </Link>
          </>
        ) : (
          <h5 className="text-center mt-5">Nothing found !!</h5>
        )}
      </div>
    </div>
  );
};

export default Search;
