import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import LoadingPage from "../../Components/Loading/Loading";
import {
  getCalendar,
  getPlatforms,
  GetTrendingMovies,
} from "../../util/Auth/API";
import "../../util/Style/property.css";
import Table from "./CalendarTable";
import "./calendar.css";
import UpcomingTable from "./UpcomingTable";
import { Spinner } from "react-bootstrap";
import FilterCalTable from "./FIltertable";
import TheaticalTable from "./Theater";
import OTTWiseTable from "./OTTWiseTable";

const Calendar = () => {
  const history = useHistory();
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [select, setSelect] = useState("New");
  const [update, setUpdate] = useState(false);
  const [platform, setPlatforms] = useState([]);

  const [newRelease, setNewRelease] = useState([]);
  const [upcomingRelease, setUpcomingRelease] = useState([]);

  const FetchPlatforms = async () => {
    try {
      const { data } = await getPlatforms();
      setPlatforms(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchPlatforms();
  }, []);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="lg" style={{ color: "#fff" }}></Spinner>
      </div>
    );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setsearchInput(searchWord);

    if (select === "New") {
      setNewRelease(
        newRelease?.filter((value) => {
          return (
            value?.name?.toLowerCase().includes(searchWord.toLowerCase()) ||
            value?.title?.toLowerCase().includes(searchWord.toLowerCase())
          );
        })
      );
    } else {
      setUpcomingRelease(
        upcomingRelease?.filter((value) => {
          return (
            value?.name?.toLowerCase().includes(searchWord.toLowerCase()) ||
            value?.title?.toLowerCase().includes(searchWord.toLowerCase())
          );
        })
      );
    }
  };

  var new_release = [];
  var upcoming = [];

  const checkDate = () => {
    const date = Date.now();

    movies?.forEach((ad) => {
      const check = new Date(ad?.streaming_date);

      if (date >= check) {
        new_release.push(ad);
      } else {
        upcoming.push(ad);
      }
    });
  };

  checkDate();

  new_release?.sort((a, b) => {
    let da = new Date(a.streaming_date),
      db = new Date(b.streaming_date);
    return db - da;
  });
  upcoming?.sort((a, b) => {
    let da = new Date(a.streaming_date),
      db = new Date(b.streaming_date);
    return da - db;
  });

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={handleFilter}
              />
            </div>

            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() => history.push("/calendar/new")}
              >
                <span>Add New Date</span>
              </button>
            </div>
          </div>

          <div className="mt-3 filter-div">
            <button
              onClick={() => setSelect("New")}
              style={{ opacity: select === "New" ? "1" : "0.7" }}
            >
              New Release
            </button>
            <button
              onClick={() => setSelect("Upcoming")}
              style={{ opacity: select === "Upcoming" ? "1" : "0.7" }}
            >
              Upcoming Release
            </button>
          </div>
          <div className="property-tableSection">
            {select === "New" &&
              (searchInput.length ? (
                <FilterCalTable
                  propertyData={newRelease}
                  setUpdate={setUpdate}
                  update={update}
                  platform={platform}
                />
              ) : (
                <Table
                  propertyData={newRelease}
                  setUpdate={setUpdate}
                  update={update}
                  platform={platform}
                  setNewRelease={setNewRelease}
                />
              ))}
            {select === "Upcoming" &&
              (searchInput.length ? (
                <FilterCalTable
                  propertyData={upcomingRelease}
                  setUpdate={setUpdate}
                  update={update}
                  platform={platform}
                />
              ) : (
                <UpcomingTable
                  propertyData={upcomingRelease}
                  setUpdate={setUpdate}
                  update={update}
                  platform={platform}
                  setUpcomingRelease={setUpcomingRelease}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Calendar;
