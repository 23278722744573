import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addNewsApi,
  addToTrending,
  addWatchListApi,
  getAllAuthor,
  getAllCategory,
  getAllTags,
  searchListById,
  searchListByName,
} from "../../util/Auth/API";
import Select from "react-select";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { uploadFile } from "../../firebase/fileupload";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TagsInput } from "../../Components/TagInput";

const AddNews = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [authorAll, setAuthorAll] = useState([]);
  const [longDescription, setLongDescription] = useState("");
  const [description, setDescription] = useState("");
  const modules = {
    toolbar: [
      [{ header: [7] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

  const modules1 = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "vertical",
    "video",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const [descption, setDescption] = useState("");
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const [author, setAuthor] = useState(null);
  const [category, setCategory] = useState("");
  const [urlTitle, setUrlTitle] = useState("");
  const [categoryAll, setCategoryAll] = useState([]);
  const [form, setform] = useState(null);
  const [tagss, setTagss] = useState([]);
  const [tagsAll, setTagsAll] = useState([]);
  const [publishedDate, setPublishedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [metaDescription, setMetaDescription] = useState("");

  const FetchData = async () => {
    setLoading(true);
    try {
      const res = await getAllCategory();
      setCategoryAll(res.data?.data);
    } catch (error) {}
  };
  const fetchTags = async () => {
    setLoading(true);
    try {
      const res = await getAllTags();
      const tagsData = res.data?.data.map((item) => item.tags);

      const unique = [...new Set(tagsData.flat())];
      const options = unique.map((i) => {
        return {
          value: String(Math.random()),
          label: i,
        };
      });
      setTagsAll(options);
    } catch (error) {}
  };

  const fetchAuthor = async () => {
    try {
      const res = await getAllAuthor();
      setAuthorAll(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
    fetchTags();
    fetchAuthor();
  }, []);

  const newsSubmit = async () => {
    if (
      !title ||
      !descption ||
      !longDescription ||
      !image ||
      !category ||
      !publishedDate
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      const res = await addNewsApi({
        title: title,
        description: descption,
        longDescription: longDescription,
        image: image.logo,
        metaDescription: metaDescription,
        publishedDate: publishedDate,
        category: category,
        tags: tagss,
        urlTitle: urlTitle,
        author: author || "Admin",
      });
      if (res.status === 200) {
        toast.success("Watchlist Added Successfully");
        history.push("/news");
        setShow(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Title Of News"
            className="me-2"
            aria-label="Search"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Custom Title (For URL)"
            className="me-2"
            aria-label="Search"
            value={urlTitle}
            onChange={(e) => setUrlTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <textarea
            type="search"
            placeholder="Enter Meta Description"
            className="me-2 form-control"
            aria-label="Search"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
        <label htmlFor={""} className="me-2">
          Description
        </label>
        <div className="d-flex w-100 mb-2">
          <ReactQuill
            theme="snow"
            width="100%"
            modules={modules}
            style={{
              width: "100%",
              height: "200px",
              color: "white",
              fontSize: "14px",
              backgroundColor: "#121212",
            }}
            id="description_id"
            formats={formats}
            defaultValue={descption}
            onChange={(content, delta, source, editor) => {
              setDescption(editor.getHTML());
            }}
          />
        </div>
        <div className="d-flex mb-3">
          <Form.Control type="file" onChange={(e) => uploadFile(e, setImage)} />
        </div>
        {image?.logo && (
          <div className="d-flex mb-3">
            <img src={image.logo} alt="logo" width="100" height="100" />
          </div>
        )}
        <div className="d-flex mb-3">
          <Form.Select onChange={(e) => setCategory(e.target.value)}>
            <option value="0">Select Category</option>
            {categoryAll?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
          <Form.Select
            className="mx-2"
            onChange={(e) => setAuthor(e.target.value)}
          >
            <option value="0">Select Author</option>
            {authorAll?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex mb-3">
          <Select
            name="access"
            width="100%"
            onChange={(e) => setTagss(e)}
            isMulti
            placeholder="Select Tags"
            options={tagsAll}
            classNamePrefix="select"
            className="w-100 text-white"
          />
        </div>
        <label className="me-2">Select Published Date</label>
        <div className="d-flex mb-3">
          <Form.Control
            type="datetime-local"
            onChange={(e) => setPublishedDate(e.target.value)}
            placeholder="Enter Date"
            className="me-2"
            aria-label="Search"
          />
        </div>
        <div className="d-flex w-100">
          <ReactQuill
            theme="snow"
            width="100%"
            modules={modules1}
            style={{
              width: "100%",
              color: "white",
              backgroundColor: "#121212",
            }}
            formats={formats}
            defaultValue={longDescription}
            onChange={(content, delta, source, editor) => {
              setLongDescription(editor.getHTML());
            }}
          />
        </div>
        <div className="d-flex my-3 justify-content-center">
          <Button className="btn btn-danger" onClick={() => newsSubmit()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
