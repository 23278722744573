import React, { useEffect, useState } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import { getUpcomingReleaseReq } from "../../util/Auth/API";
import "../../util/Style/table.css";
import WorkOuttableRow from "./UpcomingRows";

const UpcomingTable = ({
  propertyData,
  setUpdate,
  update,
  platform,
  setUpcomingRelease,
}) => {
  const [allProperty, setAllProperty] = useState([]);
  const [laoding, setLoading] = useState(false);

  const getUpcomingRelease = async () => {
    try {
      setLoading(true);
      const res = await getUpcomingReleaseReq();
      const filterData = res?.data?.data;
      setAllProperty(filterData);
      setUpcomingRelease(filterData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUpcomingRelease();
  }, []);
  return (
    <>
      {laoding ? (
        <LoadingPage />
      ) : (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Poster</th>
                <th>Tmdb Id</th>
                <th>Title</th>
                <th>Tagline</th>
                <th>Description</th>
                <th className="text-center">Select Platform</th>
                <th className="text-center">Streaming Date</th>
                <th style={{ textAlign: "center" }}>Date Visiblity</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {allProperty &&
                allProperty.map((property, index) => {
                  return (
                    <WorkOuttableRow
                      key={index}
                      index={index}
                      property={property}
                      setUpdate={setUpdate}
                      update={update}
                      platform={platform}
                      getUpcomingRelease={getUpcomingRelease}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UpcomingTable;
