import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LoadingPage from "../../Components/Loading/Loading";
import { updateCalendar, updateTheater } from "../../util/Auth/API";
import ReactQuill from "react-quill";

const EditCalendar = () => {
  const location = useLocation();
  const [description, setDescription] = useState("");
  const data = location.state?.data;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "video",
    "font",
  ];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data?.description) {
      setDescription(data.description);
    }
  }, [data]);

  const [inputFields, setInputFields] = useState({
    title: data?.name || data?.title || data?.original_name,
    streaming_date: `${new Date(data.streaming_date).getFullYear()}-${
      new Date(data.streaming_date).getMonth() + 1 > 9
        ? new Date(data.streaming_date).getMonth() + 1
        : `0${new Date(data.streaming_date).getMonth() + 1}`
    }-${
      new Date(data.streaming_date).getDate() < 10
        ? `0${new Date(data.streaming_date).getDate()}`
        : new Date(data.streaming_date).getDate()
    }`,
  });

  console.log(inputFields);
  const handleInputChange = (e) => {
    setInputFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    let body = {
      ...inputFields,
      description: description,
      calenderId: data._id,
    };

    console.log("FOrM", body);

    try {
      if (location.pathname.includes("/theater/edit/")) {
        body = {
          ...body,
          theatricalId: data._id,
        };
        await updateTheater(body);
      } else {
        await updateCalendar(body);
      }
      toast.success("Updated Successfully");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };
  console.log(data);
  return (
    <form>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="addproperty-container">
          <div className="addproperty-personalDetails">
            <div className="addproperty-alignRow">
              <div className="meal-title">
                <Link
                  to={
                    location.pathname.includes("/theater/edit/")
                      ? "/theater"
                      : "/calendar"
                  }
                >
                  <WestOutlinedIcon />
                </Link>
                <p>Back</p>
              </div>
            </div>
            {/* 1st row */}
            <div className="addproperty-alignRow">
              <div className="addproperty-inputFieldDiv form-group">
                <label className="addproperty-inputLabel ">
                  Title{" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                    }}
                  >
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="title"
                  value={inputFields?.title}
                  placeholder="Title"
                  className="addproperty-inputField"
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="addproperty-inputFieldDiv form-group">
                <label className="addproperty-inputLabel ">
                  Streaming Date{" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                    }}
                  >
                    *
                  </span>{" "}
                </label>
                <input
                  type="date"
                  name="streaming_date"
                  value={inputFields.streaming_date}
                  placeholder="Streaming Date"
                  className="addproperty-inputField"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="addproperty-alignRow">
              <div className="addproperty-inputFieldDiv form-group">
                <label className="addproperty-inputLabel ">
                  Tmdb Id{" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                    }}
                  >
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="id"
                  value={data?.webSeriesId || data?.movieId}
                  placeholder="id"
                  className="addproperty-inputField"
                  disabled
                />
              </div>
            </div>

            <div className="d-flex my-3">
              {description?.length > 0 && (
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  width="100%"
                  style={{
                    width: "100%",
                    height: "300px",
                    backgroundColor: "#121212",
                  }}
                  formats={formats}
                  value={description}
                  onChange={(content, delta, source, editor) => {
                    setDescription(editor.getHTML());
                  }}
                />
              )}
              {!description && (
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  width="100%"
                  style={{
                    width: "100%",
                    height: "300px",
                    backgroundColor: "#121212",
                  }}
                  formats={formats}
                  value={description}
                  onChange={(content, delta, source, editor) => {
                    setDescription(editor.getHTML());
                  }}
                />
              )}
            </div>

            <div className="addproperty-submitDetailDivMeal">
              <button
                className="addproperty-submitDetailBtn"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default EditCalendar;
