import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import LoadingPage from "../../Components/Loading/Loading";
import { getPlatforms } from "../../util/Auth/API";
import "../../util/Style/property.css";
import Table from "./Table";

const Platforms = () => {
  const history = useHistory();
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [update, setUpdate] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const res = await getPlatforms();
      setPlatforms(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (update) {
      loadData();
      setUpdate(false);
    }
  }, [update]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setsearchInput(searchWord);

    const newFilter = platforms?.filter((value) => {
      return value?.name?.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setfilterData([]);
    } else {
      setfilterData(newFilter);
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={handleFilter}
              />
            </div>

            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() => history.push("/platform/new")}
              >
                <span>Add New Platform</span>
              </button>
            </div>
          </div>

          <div className="property-tableSection">
            {searchInput.length ? (
              <Table
                data={filterData}
                setUpdate={setUpdate}
                loadData={loadData}
              />
            ) : (
              <Table
                data={platforms}
                setUpdate={setUpdate}
                loadData={loadData}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Platforms;
