import axios from "axios";

// for live server
const API = axios.create({
  baseURL: "https://staging-api.flicksbuddy.com",
  // baseURL: "http://localhost:2222",
  headers: {
    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    published: true,
  },
});

export const GetTrendingMovies = () => API.get("/list/trendings");

export const getTrendingMovies = () => API.get("/list/movieTrendings");
export const getTrendingSeries = () => API.get("/list/webTrendings");
export const getTickTrendings = () => API.get("/list/trendings?title=true");
export const getNewReleaseReq = () => API.get("/list/new");
export const getNewTheaterReq = () =>
  API.get("/list/newTheatrical?isAdmin=true");
export const getUpcomingReleaseReq = () => API.get("/list/upcoming");
export const getUpcomingTheater = () =>
  API.get("/list/upcomingTheatrical?isAdmin=true");

export const searchList = (q) => API.get(`/list/search?name=${q}`);
export const searchListByName = (q) => API.get(`/list/search?name=${q}`);
export const searchListById = (q) => API.get(`/list/search?tmdbid=${q}`);
export const addToTrending = (body) => API.post("/list/addToTrending", body);
export const addWatchListApi = (body) => API.post("/create-watchlist", body);
export const addNewsApi = (body) => API.post("/create-news", body);
export const updateWatchList = (body, id) =>
  API.patch(`/update-watchlist`, body);
export const updateWatchlistPublish = (body) =>
  API.patch(`/update-watchlist-publish`, body);
export const updateNews = (body, id) => API.patch(`/update-news`, body);
export const updateNewsPublish = (body) => API.patch(`/publish-news`, body);
export const schedulNews = (body) => API.patch(`/schedule-news`, body);
export const updateWatchListHomepage = (body) =>
  API.patch(`/update-watchlist-homepage`, body);
export const updateWatchListOTT = (body) =>
  API.patch(`/update-watchlist-ott`, body);
export const updateWatchlistOrder = (body) =>
  API.patch(`/update-watchlist-order`, body);
export const updateNewsHomepage = (body) =>
  API.patch(`/update-news-homepage`, body);
export const deleteWatchList = (id) => API.delete(`/delete-watchlist/${id}`);

export const getWatchList = () => API.get("/get-watchlist");
export const getAllCategory = (type = "NEWS") =>
  API.get(`/admin/get-category?type=${type}`);
export const createCategory = (body, type) =>
  API.post(`/admin/create-category?type=${type}`, body);
export const createTags = (body, type) =>
  API.post(`/admin/create-tags?type=${type}`, body);
export const updateCategory = (body) =>
  API.patch("/admin/update-category", body);
export const getCategoryById = (id) => API.get(`/admin/get-category/${id}`);
export const deleteCategory = (body) =>
  API.post("/admin/delete-category", body);
export const deleteAuthor = (body) => API.post("/admin/delete-author", body);
export const getAllTags = (type = "NEWS") =>
  API.get(`/admin/get-tags?type=${type}`);
export const getAllAuthor = (type = "NEWS") =>
  API.get(`/admin/get-author?type=${type}`);
export const createAuthor = (body, type) =>
  API.post(`/admin/create-author?type=${type}`, body);
export const getAuthorById = (id) => API.get(`/admin/get-author/${id}`);
export const updateAuthor = (id) => API.patch(`/admin/update-author`, id);
export const deteleAuthor = (id) => API.post("/admin/create-author", id);
export const getNews = () => API.get("/get-news");
export const deleteTags = (body) => API.post("/admin/delete-tags", body);
export const getWatchListSingle = (id) => API.get(`/watchlist/${id}`);
export const getTagsById = (id) => API.get(`/admin/get-tags/${id}`);
export const updateTags = (body) => API.patch(`/admin/update-tags`, body);
export const fetchNewsById = (id) => API.get(`/news/${id}`);
export const deleteTrending = (id) => API.post("/list/removeFromTrending", id);
export const updateTrending = (body) => API.post("/list/updateTrending", body);

export const getCalendar = () => API.get("/list/calender");
export const addToCalendar = (body) => API.post("/list/addToCalender", body);
export const addToTheater = (body) => API.post("/list/addToTheatrical", body);
export const updateCalendar = (body) => API.post("/list/updateCalender", body);
export const updateTheater = (body) => API.post("/list/updateTheatrical", body);
export const deleteCalendar = (body) =>
  API.post("/list/removeFromCalender", body);
export const deleteTheater = (body) =>
  API.post("list/removeFromTheatrical", body);

export const updateMovie = (body) => API.post("/movie/update", body);
export const updateSeries = (body) => API.post("/web/update", body);

export const getMovie = (id) => API.get(`/movie/byId?movieId=${id}`);
export const getSeries = (id) => API.get(`/web/byId?webSeriesId=${id}`);
export const getSeason = (id, number) =>
  axios.get(
    `https://api.themoviedb.org/3/tv/${id}/season/${number}?api_key=14d9408c701b3a945daec63b2c4a299a`
  );

export const getPlatforms = () => API.get(`/ott`);
export const addNewPlatform = (body) => API.post(`/ott/add`, body);
export const updatePlatform = (body) => API.post(`/ott/update`, body);
export const deletePlatform = (body) => API.post(`/ott/delete`, body);

export const getMovieGenres = () =>
  API.get(
    "https://api.themoviedb.org/3/genre/movie/list?api_key=14d9408c701b3a945daec63b2c4a299a"
  );

export const getBanner = () => API.get(`/control/banner`);
export const addBanner = (body) => API.post(`/control/addBanner`, body);
export const updateBanner = (body) => API.post(`/control/updateBanner`, body);
export const deleteBanner = (body) => API.post(`/control/deleteBanner`, body);
export const AddAbout = (body) => API.post(`/control/addAbout`, body);
export const getTerms = () => API.get(`/control/terms`);
export const addTerms = (body) => API.post(`/control/addTerms`, body);
export const getPolicy = () => API.get(`/control/policy`);
export const addPolicy = (body) => API.post(`/control/addPolicy`, body);
export const getAbout = () => API.get(`/control/about`);

export const loginAdmin = (formData) => API.post("/admin/login", formData);
export const addSubAdmin = (formData) => API.post("/admin/addAdmin", formData);
export const getSubAdmin = () => API.get("/admin/getAdmins");
export const editSubAdmin = (formData) =>
  API.post("/admin/updateAdmin", formData);
export const deleteSubAdmin = (data) => API.post("/admin/deleteAdmin", data);

export const getCronsReq = () => API.get("/control/log");
export const updateMoviesCronsReq = (startDate, endDate) =>
  API.get(`/movie/populateData?start_date=${startDate}&end_date=${endDate}`);
export const updateSeriesCronsReq = (startDate, endDate) =>
  API.get(`/web/populateData?start_date=${startDate}&end_date=${endDate}`);
