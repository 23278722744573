import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { storage } from "../../util/Firebase/Firebase.init";
import "../../util/Style/addPropertyForm.css";

const EditTrending = () => {
  const location = useLocation();
  const data = location.state?.data;

  const [spinn, setspinn] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: data?.title || "",
    tagline: data?.tagline || "",
  });
  const handleInputchange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  //file upload
  const fileUpload = async (file) => {
    const storageRef = ref(storage, `files/${file.name}`);
    await uploadBytesResumable(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log(inputFields);
  };

  return (
    <form>
      <div className="addproperty-container">
        <div className="addproperty-personalDetails">
          <div className="addproperty-alignRow">
            <div className="meal-title">
              <Link to="/trending">
                <WestOutlinedIcon />
              </Link>
              <p>Edit</p>
            </div>
          </div>
          {/* 1st row */}
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Title{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="title"
                defaultValue={inputFields?.title}
                placeholder="Video Title"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Tagline{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="title"
                defaultValue={inputFields?.tagline}
                placeholder="Video Title"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>

          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              Update
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditTrending;
