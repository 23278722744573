import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import searchIcon from "../../assets/searchIcon.svg";
import LoadingPage from "../../Components/Loading/Loading";
import {
  getPlatforms,
  GetTrendingMovies,
  getWatchList,
} from "../../util/Auth/API";
import "../../util/Style/property.css";
import FilterTable from "./FilterTable";
import HomepageTable from "./HomepageTable";
import SeriesTable from "./SeriesTable";
import MyTable from "./Table";
import { Spinner } from "react-bootstrap";
// import Table from "./TrendingMovieTable";

const WatchlistMain = () => {
  const history = useHistory();
  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [platform, setPlatforms] = useState([]);

  const [trendingHome, setTrendingHome] = useState([]);

  const FetchPlatforms = async () => {
    try {
      const { data } = await getWatchList();
      setPlatforms(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchPlatforms();
  }, []);

  useEffect(() => {
    if (update) {
      setUpdate(false);
      setfilterData([]);
      setsearchInput("");
    }
  }, [update]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="lg" style={{ color: "#fff" }}></Spinner>
      </div>
    );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setsearchInput(searchWord);
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={handleFilter}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() => history.push("/watch-list/new")}
              >
                <span>Add New</span>
              </button>
            </div>
          </div>

          <div className="property-tableSection">
            {searchInput.length ? (
              <FilterTable
                data={platform}
                setUpdate={setUpdate}
                setTrending={setTrendingHome}
                platform={platform}
              />
            ) : (
              <HomepageTable
                data={platform}
                setUpdate={setUpdate}
                setTrending={setTrending}
                platform={platform}
                setTrendingHome={setTrendingHome}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WatchlistMain;
