import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import {
  deleteTrending,
  getPlatforms,
  updateTrending,
} from "../../util/Auth/API";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import DeleteModal from "../../util/Other/DeleteModal";
import { useEffect } from "react";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => <Handle className="handle">||||</Handle>);

const SeriesRow = ({
  index,
  no,
  property,
  className,
  setUpdate,
  allProperty,
  setAllProperty,
  setTrending,
  platform,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);

  const platformHandle = async (state) => {
    const body = {
      trendingId: property._id,
      ott: state,
    };

    try {
      await updateTrending(body);
      toast.success("Updated Successfully !!");
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };
  const updateHomepage = async (state) => {
    const body = {
      trendingId: property._id,
      isTitle: state,
    };

    try {
      await updateTrending(body);
      toast.success("Updated Successfully !!");
      setUpdate(true);
    } catch (err) {
      toast.error("Something went wrong!!");
      console.log(err);
    }
  };

  const handleDeleteConfirm = () => {
    setdeleteModalOpen(false);
    setConfirmDelete(true);
  };
  const handleDeleteCancel = () => {
    setdeleteModalOpen(false);
  };
  const handleDeleteProperty = (e) => {
    e.preventDefault();
    setdeleteModalOpen(true);
  };

  const handleConfirmDeleteProperty = async (id) => {
    try {
      const updatedproperty = allProperty.filter((b) => b._id !== id);
      setAllProperty(updatedproperty);
      setTrending(updatedproperty);
      await deleteTrending({
        trendingId: id,
      });
      setUpdate(true);
      toast.success("Deleted successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };
  useEffect(() => {
    if (ConfirmDelete) {
      handleConfirmDeleteProperty(property._id);
    }
  }, [ConfirmDelete]);

  console.log(property);

  return (
    <TrWrapper className={className}>
      <td>
        <div className="firstElement">
          <RowHandler />
          {no}
        </div>
      </td>
      <td className="image-td">
        <img
          src={`https://image.tmdb.org/t/p/original/${property.poster_path}`}
          alt="Poster"
        />
      </td>
      <td>{property.movieId || property.webSeriesId}</td>
      <td>{property?.name || property?.title}</td>
      <td>{property.tagline}</td>
      <td className="text-center">
        <Multiselect
          style={{ border: "0" }}
          multiple
          value={property.ott || "select"}
          options={platform}
          displayValue="name"
          showCheckbox={true}
          selectedValues={property?.ott?.map((item) => {
            return { name: item?.name, _id: item?._id };
          })}
          onSelect={(e) => platformHandle(e.map((item) => item._id))}
          onRemove={(e) => platformHandle(e.map((item) => item._id))}
        ></Multiselect>
      </td>
      <td className="text-center">
        <input
          type="checkbox"
          checked={property.isTitle}
          onChange={() => updateHomepage(!property.isTitle)}
        />
      </td>
      <td className="text-right">
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {/* <Link
            to={{
              pathname: `/trending/edit/${property._id}`,
              state: { data: property },
            }}
          >
            {" "}
            <button className="edit-btn">
              <ModeEditIcon />{" "}
            </button>
          </Link> */}
          <button
            onClick={(e) => handleDeleteProperty(e)}
            className="delete-btn"
          >
            <DeleteIcon />{" "}
          </button>
        </div>
      </td>
      {deleteModalOpen && (
        <DeleteModal
          show={deleteModalOpen}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          categorytag={property.title}
        />
      )}
    </TrWrapper>
  );
};

export default SeriesRow;
