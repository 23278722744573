import React, { useCallback, useEffect, useState } from "react";
import "../../util/Style/table.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { deletePlatform, updatePlatform } from "../../util/Auth/API";
import { toast } from "react-hot-toast";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TableRow from "./TableRow";
import arrayMove from "../TrendingMovies/arrayMove";

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);

const Table = ({ data, setUpdate, loadData }) => {
  const [items, setItems] = useState(data);
  const [isOttUpdate, setIsOttUpdate] = useState(false);
  //   useEffect(() => {
  //     setAllProperty(propertyData);
  //   }, [propertyData]);

  //   const [allProperty, setAllProperty] = useState(propertyData);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    setIsOttUpdate(true);
  });

  useEffect(() => {
    if (items.length && isOttUpdate) {
      const updateOrderData = async () => {
        try {
          const ottData = items.map((item, index) => {
            item.order = index + 1;
            return item;
          });
          const response = await updatePlatform({
            ottData,
          });
          console.log(response);
          setIsOttUpdate(false);
        } catch (error) {
          console.log(error);
          setIsOttUpdate(false);
        }
      };
      updateOrderData();
    }
  }, [items]);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>Serial</th>
            <th>Logo</th>
            <th>Platform Name</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        {/* <tbody> */}
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {items?.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              property={value}
              no={index + 1}
              setUpdate={setUpdate}
            />
          ))}
        </SortableCont>
        {/* {data?.map((d, index) => (
            <tr key={d._id}>
              <td>{index + 1}</td>
              <td className="image-td rounded">
                <img src={d?.logo} alt="logo" />
              </td>
              <td>{d.name}</td>
              <td className="text-center">
                <div
                  className="actions"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: `/platform/edit`,
                      state: { data: d },
                    }}
                  >
                    {" "}
                    <button className="edit-btn">
                      <ModeEditIcon />{" "}
                    </button>
                  </Link>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(d._id)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))} */}
        {/* </tbody> */}
      </table>
    </div>
  );
};

export default Table;
