import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "./arrayMove";
import { useEffect } from "react";
import { getTickTrendings, updateTrending } from "../../util/Auth/API";
import SeriesRow from "./SeriesRow";
import LoadingPage from "../../Components/Loading/Loading";

const MyTableWrapper = styled.div`
  padding: 10px;

  .fixed_header {
    width: 800px;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      display: block;
      width: 807px;
      overflow: auto;
      height: 400px;
      cursor: grabbing;
      background: grey;
    }

    & > thead {
      background: yellow;
      color: black;

      & > tr {
        display: block;
        //width: 793px;
      }
    }

    & > thead th,
    & > tbody td {
      padding: 5px;
      text-align: left;
      width: 200px;
      border: 1px solid #000;
    }
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <SeriesRow {...props} />);

const HomepageTable = ({ searchInput, setUpdate, setTrending, platform, setTrendingHome }) => {
  const [items, setItems] = useState([]);
  const [laoding, setLoading] = useState(false);

  const getTrendingHomeData = async () => {
    try {
      setLoading(true);
      const res = await getTickTrendings();
      setItems(res?.data?.data);
      setTrendingHome(res?.data?.data)
      setAllProperty(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTrendingHomeData();
  }, []);

  const [allProperty, setAllProperty] = useState([]);

  const updateOrders = async (oldIndex, newIndex) => {
    const body = {
      trendingId: items[oldIndex]?._id,
      position: newIndex,
    };
    try {
      const res = await updateTrending(body);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));

      updateOrders(oldIndex, newIndex);
    },
    [updateOrders]
  );

  return (
    <>
    {
      laoding ? (
        <LoadingPage />
      ) : (
      <MyTableWrapper className="table-wrapper" id="#scrollBar">
        <table className="fl-table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Poster</th>
              <th>Tmdb Id</th>
              <th>Title</th>
              <th>Tagline</th>
              <th className="text-center">Select Platform</th>
              <th className="text-center">HomePage</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={["30%", "50%"]}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {items?.map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                property={value}
                no={index + 1}
                setUpdate={setUpdate}
                allProperty={allProperty}
                setTrending={setItems}
                setAllProperty={setAllProperty}
                platform={platform}
              />
            ))}
          </SortableCont>
        </table>
      </MyTableWrapper>

      )
    }
    </>
  );
};

export default HomepageTable;
