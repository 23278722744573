import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import {
  fetchNewsById,
  getAllAuthor,
  getAllCategory,
  getAllTags,
  updateNews,
} from "../../util/Auth/API";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { uploadFile } from "../../firebase/fileupload";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const EditNews = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState(null);
  const [urlTitle, setUrlTitle] = useState("");

  const [longDescription, setLongDescription] = useState("");
  const [categoryAll, setCategoryAll] = useState([]);
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const modules = {
    toolbar: [
      [{ header: [7] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };
  const modules1 = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "video",
    "font",
  ];
  const [selectData, setSelectData] = useState([]);
  const [descption, setDescption] = useState("");
  const [authorAll, setAuthorAll] = useState([]);
  const [image, setImage] = useState(null);
  const [tagss, setTagss] = useState([]);
  const [tagsAll, setTagsAll] = useState([]);
  const [show, setShow] = useState(false);
  const [publishedDate, setPublishedDate] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [authorName, setAuthorName] = useState("");

  const fetchTags = async () => {
    try {
      const res = await getAllTags();
      const tagsData = res.data?.data.map((item) => item.tags);

      const unique = [...new Set(tagsData.flat())];
      const options = unique.map((i) => {
        return {
          value: String(Math.random()),
          label: i,
        };
      });
      setTagsAll(options);
    } catch (error) {}
  };

  const fetchAuthor = async () => {
    try {
      const res = await getAllAuthor();
      setAuthorAll(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchNewsById(id);
      setTitle(res.data?.data?.title);
      setDescription(res.data?.data.description);
      setLongDescription(res.data?.data.longDescription);
      setPublishedDate(res.data?.data.publishedDate);
      setCategory(res.data?.data.category?.name);
      setAuthorName(res.data?.data.author?.name);
      setMetaDescription(res.data?.data.metaDescription);
      setCategoryId(res.data?.data.category?._id);
      setUrlTitle(res.data?.data.urlTitle);
      const options = res.data?.data.tags.map((i) => {
        return {
          value: String(Math.random()),
          label: i,
        };
      });
      setTagss(options);
      setImage({ logo: res.data?.data.image });
      setAuthor(res.data?.data.author?._id);
    };
    fetchData();
    fetchTags();
    fetchAuthor();
  }, []);

  const newsSubmit = async () => {
    const movies = selectData.filter((i) => i.movieId);
    const webseries = selectData.filter(
      (i) => i.webSeriesId && !i.seasonNumber
    );
    const seasons = selectData.filter((i) => i.seasonNumber);
    console.log(movies, webseries, seasons);
    try {
      const res = await updateNews({
        title: title,
        id: id,
        description: description,
        longDescription: longDescription,
        image: image.logo,
        publishedDate: publishedDate,
        category: categoryId,
        metaDescription: metaDescription,
        urlTitle: urlTitle,
        tags: tagss.map((i) => i.label),
        author: author || "Admin",
      });
      if (res.status === 200) {
        toast.success("Watchlist Added Successfully");
        history.push("/news");
        setShow(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const FetchData = async () => {
    try {
      const res = await getAllCategory();
      console.log(res);
      setCategoryAll(res.data?.data);
      debugger;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  console.log(categoryAll);

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Title Of News"
            className="me-2"
            aria-label="Search"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            placeholder="Enter Custom Title (For URL)"
            className="me-2"
            aria-label="Search"
            value={urlTitle}
            onChange={(e) => setUrlTitle(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <textarea
            type="search"
            placeholder="Enter Meta Description"
            className="me-2 form-control"
            aria-label="Search"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
        <label htmlFor={""} className="me-2">
          Description
        </label>
        <div className="d-flex w-100 mb-2">
          {description?.length > 0 && (
            <ReactQuill
              theme="snow"
              width="100%"
              id="description_id"
              modules={modules}
              style={{
                width: "100%",
                height: "200px",
                color: "white",
                backgroundColor: "#121212",
              }}
              formats={formats}
              defaultValue={description}
              onChange={(content, delta, source, editor) => {
                setDescription(editor.getHTML());
              }}
            />
          )}
        </div>
        <div className="d-flex mb-3">
          <Form.Control type="file" onChange={(e) => uploadFile(e, setImage)} />
        </div>
        {image?.logo && (
          <div className="d-flex mb-3">
            <img src={image.logo} alt="logo" width="100" height="100" />
          </div>
        )}
        <div className="d-flex mb-3">
          <Form.Select onChange={(e) => setCategoryId(e.target.value)}>
            <option value={categoryId}>{category}</option>
            {categoryAll?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
          <Form.Select
            className="mx-2"
            onChange={(e) => setAuthor(e.target.value)}
          >
            <option value={author}>{authorName}</option>
            {authorAll?.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex mb-3">
          <Select
            name="access"
            width="100%"
            onChange={(e) => setTagss(e)}
            isMulti
            value={tagss}
            placeholder="Select Tags"
            options={tagsAll}
            classNamePrefix="select"
            className="w-100 text-white"
          />
        </div>
        <label className="me-2">Select Published Date</label>
        <div className="d-flex mb-3">
          <Form.Control
            defaultValue={publishedDate?.slice(0, 16)}
            type="datetime-local"
            onChange={(e) => setPublishedDate(e.target.value)}
            placeholder="Enter Date"
            className="me-2"
            aria-label="Search"
          />
        </div>
        <div className="d-flex">
          {longDescription?.length > 0 && (
            <ReactQuill
              theme="snow"
              modules={modules1}
              width="100%"
              style={{
                width: "100%",
                backgroundColor: "#121212",
              }}
              formats={formats}
              value={longDescription}
              onChange={(content, delta, source, editor) => {
                setLongDescription(editor.getHTML());
              }}
            />
          )}
        </div>
        <div className="d-flex my-3 justify-content-center">
          <Button className="btn btn-danger" onClick={() => newsSubmit()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditNews;
