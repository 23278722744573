import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "./arrayMove";
import { useEffect } from "react";
import {
  getTickTrendings,
  getWatchList,
  getPlatforms,
  updateTrending,
  updateWatchlistOrder,
} from "../../util/Auth/API";
import SeriesRow from "./SeriesRow";
import LoadingPage from "../../Components/Loading/Loading";

const MyTableWrapper = styled.div`
  padding: 10px;

  .fixed_header {
    width: 800px;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      display: block;
      width: 807px;
      overflow: auto;
      height: 400px;
      cursor: grabbing;
      background: grey;
    }

    & > thead {
      background: yellow;
      color: black;

      & > tr {
        display: block;
        //width: 793px;
      }
    }

    & > thead th,
    & > tbody td {
      padding: 5px;
      text-align: left;
      width: 200px;
      border: 1px solid #000;
    }
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <SeriesRow {...props} />);

const HomepageTable = ({
  searchInput,
  setUpdate,
  setTrending,
  platform,
  setTrendingHome,
}) => {
  const [items, setItems] = useState([]);
  const [laoding, setLoading] = useState(false);
  const [platforms, setPlatForm] = useState([]);
  const [isOttUpdate, setIsOttUpdate] = useState(false);

  const getTrendingHomeData = async () => {
    try {
      setLoading(true);
      const res = await getWatchList();
      const orderedList = res.data.data.sort((a, b) => a.order - b.order);
      setItems(orderedList);
      setTrendingHome(orderedList);
      setAllProperty(orderedList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getPlatform = async () => {
    try {
      setLoading(true);
      const res = await getPlatforms();
      setPlatForm(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTrendingHomeData();
    getPlatform();
  }, []);

  const [allProperty, setAllProperty] = useState([]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    setIsOttUpdate(true);
  });

  useEffect(() => {
    if (items.length && isOttUpdate) {
      const updateOrderData = async () => {
        try {
          const ottData = items.map((item, index) => {
            item.order = index + 1;
            return item;
          });
          const response = await updateWatchlistOrder({
            order: ottData,
          });
          console.log(response);
          setIsOttUpdate(false);
        } catch (error) {
          console.log(error);
          setIsOttUpdate(false);
        }
      };
      updateOrderData();
    }
  }, [items]);

  return (
    <>
      {laoding ? (
        <LoadingPage />
      ) : (
        <MyTableWrapper className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Poster</th>
                <th>Title</th>
                <th className="text-center">Description</th>
                <th className="text-center">Total Items</th>
                <th className="text-center">Publish</th>
                <th className="text-center">Platform</th>
                <th className="text-center">Genres</th>
                <th className="text-center">Language</th>
                <th className="text-center">Type</th>
                <th className="text-center">HomePage</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <SortableCont
              onSortEnd={onSortEnd}
              axis="y"
              lockAxis="y"
              lockToContainerEdges={true}
              lockOffset={["30%", "50%"]}
              helperClass="helperContainerClass"
              useDragHandle={true}
            >
              {items?.map((value, index) => (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  property={value}
                  no={index + 1}
                  setUpdate={setUpdate}
                  allProperty={allProperty}
                  setTrending={setItems}
                  platforms={platforms}
                  setAllProperty={setAllProperty}
                  platform={platform}
                />
              ))}
            </SortableCont>
          </table>
        </MyTableWrapper>
      )}
    </>
  );
};

export default HomepageTable;
