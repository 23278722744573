import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  createCategory,
  getCategoryById,
  updateCategory,
} from "../../util/Auth/API";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const EditCategory = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const fetchCategoryById = async () => {
      try {
        const { data } = await getCategoryById(id);
        setName(data?.data?.name);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCategoryById();
  }, []);

  const handleSubmit = async (e) => {
    if (!name) return toast.error("Please enter a name");
    setLoading(true);
    e.preventDefault();
    try {
      await updateCategory({ name, id });
      toast.success("Added to trending");
      setLoading(false);
      history.push("/category");
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <Form.Control
            type="search"
            required
            placeholder="Enter Name Of Category"
            className="me-2 w-50"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="d-flex mb-3">
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
