import React, { useMemo, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { addToTrending, createCategory, createTags } from "../../util/Auth/API";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { TagsInput } from "../../Components/TagInput";

const AddTags = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [tagss, setTagss] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const type = searchParams.get("type");
  const handleSubmit = async (e) => {
    if (tagss.length === 0) return toast.error("Please enter a Tags");
    setLoading(true);
    e.preventDefault();
    try {
      await createTags({ tags: tagss }, type);
      toast.success("Successfully added");
      setLoading(false);
      history.push("/tags");
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex mb-3">
          <TagsInput tagss={tagss} setTagss={setTagss} />
        </div>
        <div className="d-flex mb-3">
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTags;
