import React, { useState } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import Table from "react-bootstrap/Table";
import SubAdminModal from "./SubAdminModal.jsx";
import "./SubAdmin.css";
import SubAdminTabsModal from "./SubAdminTabsModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSubAdmin, getSubAdmin } from "../../util/Auth/API";
import { useEffect } from "react";
import SubAdminEdit from "./SubAdminEdit";
import { toast } from "react-hot-toast";

const SubAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [allSubAdmins, setAllSubAdmins] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [tabData, setTabData] = useState([]);
  const adminAccess = [
    {
      value: String(Math.random()),
      label: "Trending",
    },
    {
      value: String(Math.random()),
      label: "OTT Calendar",
    },
    {
      value: String(Math.random()),
      label: "Database Edit",
    },
    {
      value: String(Math.random()),
      label: "Set Platforms",
    },
    {
      value: String(Math.random()),
      label: "Master Data",
    },
    {
      value: String(Math.random()),
      label: "Cron Jobs",
    },
    {
      value: String(Math.random()),
      label: "Sub Admins",
    },
    {
      value: String(Math.random()),
      label: "Watch List",
    },
    {
      value: String(Math.random()),
      label: "Theatrical",
    },
    {
      value: String(Math.random()),
      label: "OTT Wise",
    },
    {
      value: String(Math.random()),
      label: "Tags",
    },
    {
      value: String(Math.random()),
      label: "News",
    },
    {
      value: String(Math.random()),
      label: "Category",
    },
    {
      value: String(Math.random()),
      label: "Author",
    },
  ];

  const getAllSubAdmins = async () => {
    setLoading(true);
    try {
      const res = await getSubAdmin();
      if (res?.data?.success) {
        setAllSubAdmins(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const viewTabs = (item) => {
    if (item?.role === "Admin") {
      setTabData(adminAccess);
    } else {
      setTabData(item?.access);
    }
    handleShow2();
  };

  const editHandler = (item) => {
    setEditedData(item);
    handleShow3();
  };

  const deleteHandler = async (id) => {
    alert("Do you want to delete?");
    try {
      const res = await deleteSubAdmin({ id });
      if (res?.data?.success) {
        setAllSubAdmins(allSubAdmins?.filter((item) => item?._id !== id));
        toast.success("Deleted Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getAllSubAdmins();
  }, []);

  return (
    <div className="subAdmin-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <div className="subAdmin-topSection">
            <h4>SubAdmin Lists</h4>
            <button onClick={handleShow} className="subAdmin-button">
              <span>Add Sub Admin</span>
            </button>
            <SubAdminModal
              show={show}
              handleClose={handleClose}
              editedData={editedData}
              allSubAdmins={allSubAdmins}
              setAllSubAdmins={setAllSubAdmins}
            />
          </div>

          <Table className="subAdmin-tableContainer" bordered>
            <thead className="subAdmin-tableHeader">
              <tr className="subAdmin-tableHeader">
                <th>Actor</th>
                <th>Name</th>
                <th>Email</th>
                <th>Username</th>
                <th>Access Tabs</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="subAdmin-tableBody">
              {allSubAdmins?.map((item) => {
                return (
                  <tr className="subAdmin-tableRow">
                    <td onClick={() => viewTabs(item)}>{item?.role}</td>
                    <td onClick={() => viewTabs(item)}>{item?.name}</td>
                    <td onClick={() => viewTabs(item)}>{item?.email}</td>
                    <td onClick={() => viewTabs(item)}>{item?.username}</td>
                    <td onClick={() => viewTabs(item)}>
                      {item?.role === "Admin" ? (
                        <span>All Tab Access (6)</span>
                      ) : (
                        <span>{`Tab Access (${item?.access?.length})`}</span>
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <EditIcon
                          onClick={() => editHandler(item)}
                          titleAccess="Edit SubAdmin"
                          className="me-3 subAdmin-edit"
                        />
                        <DeleteIcon
                          onClick={() => deleteHandler(item?._id)}
                          titleAccess="Delete SubAdmin"
                          className="subAdmin-delete"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}

              <SubAdminEdit
                show={show3}
                handleClose={handleClose3}
                editedData={editedData}
                setEditedData={setEditedData}
                allSubAdmins={allSubAdmins}
                setAllSubAdmins={setAllSubAdmins}
              />
              <SubAdminTabsModal
                show={show2}
                handleClose={handleClose2}
                data={tabData}
              />
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default SubAdmin;
