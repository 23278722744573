import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getMovie, updateMovie } from "../../util/Auth/API";
import { storage } from "../../util/Firebase/Firebase.init";
import "../../util/Style/addPropertyForm.css";
import { movieGenres } from "../../util/genresData.js";
import { MultiSelect } from "react-multi-select-component";

const EditMovie = () => {
  const location = useLocation();
  const data = location.state?.data;
  const [loading, setLoading] = useState(false);
  const [genres, setGenres] = useState([]);

  const [inputFields, setInputFields] = useState();

  console.log(data);
  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getMovie(location.state?.data?.movieId);
        setLoading(false);
        const setGenreArray = [];
        data.data?.genres.forEach((element) => {
          setGenreArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setGenres(setGenreArray);
        setInputFields(data?.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (data) FetchData();
  }, []);

  if (loading) return <h5>Loading...</h5>;

  const options = [];
  movieGenres.forEach((element) => {
    options.push({
      value: element.id,
      label: element.name,
    });
  });

  const handleInputchange = (e) => {
    console.log(e);
    const { name, value } = e.target;

    setInputFields({ ...inputFields, [name]: value });
  };
  const handleInputchange1 = (e) => {
    const { name, checked } = e.target;

    setInputFields({ ...inputFields, [name]: checked });
  };

  const handlesubmit = async (e) => {
    // setspinn(true);
    e.preventDefault();

    const genrebody = [];
    genres.forEach((element) => {
      genrebody.push({
        id: element.value,
        name: element.label,
      });
    });

    const body = {
      ...inputFields,
      movieId: data.movieId,
      runtime: parseInt(inputFields.runtime),
      genres: genrebody,
    };
    // console.log("body ", body);
    try {
      await updateMovie(body);
      toast.success("Updated Successfully");
      // setspinn(false);
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
      // setspinn(false);
    }
  };

  return (
    <form>
      <div className="addproperty-container">
        <div className="addproperty-personalDetails">
          <div className="addproperty-alignRow">
            <div className="meal-title">
              <Link to="/search">
                <WestOutlinedIcon />
              </Link>
              <p>Back</p>
            </div>
          </div>
          {/* 1st row */}
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Title{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="title"
                defaultValue={inputFields?.title}
                placeholder="Title"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Tagline{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="tagline"
                defaultValue={inputFields?.tagline}
                placeholder="Tagline"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Language{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="original_language"
                defaultValue={inputFields?.original_language}
                placeholder="Language"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>

            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Overwiew{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              {/* <input
                type="text"
                name="overview"
                defaultValue={inputFields?.overview}
                placeholder="Overview"
                className="addproperty-inputField"
                onChange={handleInputchange}
              /> */}
              <textarea
                name="overview"
                rows="5"
                cols="50"
                defaultValue={inputFields?.overview}
                placeholder="Overview"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Release Date{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="release_date"
                defaultValue={inputFields?.release_date}
                placeholder="Release Date"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Status{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="status"
                defaultValue={inputFields?.status}
                placeholder="Status"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Runtime{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="title"
                defaultValue={inputFields?.runtime}
                placeholder="Runtime"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>

            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Original Title{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="original_title"
                defaultValue={inputFields?.original_title}
                placeholder="Overview"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div
              className="addproperty-inputFieldDiv form-group"
              style={{ width: "100%" }}
            >
              <label className="addproperty-inputLabel ">
                Alternate Overwiew{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="alternativeOverview"
                defaultValue={inputFields?.alternativeOverview}
                placeholder="Alternate Overwiew"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>
          <div className="addproperty-alignRow">
            <div
              className="addproperty-inputFieldDiv form-group d-flex align-items-center flex-row"
              style={{ width: "100%" }}
            >
              <label
                style={{ width: "14rem" }}
                className="addproperty-inputLabel "
              >
                Show Alternate Overwiew{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="checkbox"
                // checked={inputFields?.alternative}
                name="alternative"
                value={inputFields?.alternative}
                className="addproperty-inputField"
                onChange={handleInputchange1}
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Alternate Poster{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="alternativePoster"
                defaultValue={inputFields?.alternativePoster}
                placeholder="Alternate Poster"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                Alternate Backdrop{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="alternativeBackdrop"
                defaultValue={inputFields?.alternativeBackdrop}
                placeholder="Alternate Backdrop"
                className="addproperty-inputField"
                onChange={handleInputchange}
              />
            </div>
          </div>

          <div className="addproperty-alignRow">
            <div
              className="addproperty-inputFieldDiv form-group"
              style={{ width: "100%" }}
            >
              <label className="addproperty-inputLabel">
                Genres{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <MultiSelect
                className="genreSelect"
                style={{ background: "transparent" }}
                options={options}
                value={genres}
                onChange={setGenres}
                labelledBy="Select"
              />
            </div>
          </div>
          <div className="addproperty-alignRow">
            <div className="addproperty-inputFieldDiv form-group">
              <label className="addproperty-inputLabel ">
                TMDB Id{" "}
                <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
              </label>
              <input
                type="text"
                name="id"
                defaultValue={data?.movieId}
                placeholder="tmdb id"
                className="addproperty-inputField"
                disabled
              />
            </div>
          </div>

          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditMovie;
