import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Button, Form, Row, Col } from "react-bootstrap";
import { addSubAdmin, updateMoviesCronsReq, updateSeriesCronsReq } from "../../util/Auth/API";
import { useEffect } from "react";
import LoadingPage from "../../Components/Loading/Loading";
import { toast } from "react-hot-toast";

const CronModal = (props) => {
  const { show, handleClose, getAllCrons } = props;
  const [laoding, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: ""
  });
  const [type, setType] = useState("movie");

  const handleChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res;
      if(type === "movie") {
        res = await updateMoviesCronsReq(formData?.startDate, formData?.endDate);
      }
      if(type === "web") {
        res = await updateSeriesCronsReq(
          formData?.startDate,
          formData?.endDate
        );
      }
      console.log(res);
      if (res?.data?.success) {
        handleClose();
        toast.success("Added Successfully");
        getAllCrons();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  }

  return (
    <Modal className="subAdmin-modal" centered show={show} onHide={handleClose}>
      <Modal.Header className="subAdmin-modal-header" closeButton>
        <Modal.Title>Cron Jobs</Modal.Title>
      </Modal.Header>
      <Modal.Body className="subAdmin-modal-body">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col className="col-12 mb-3">
              <Form.Label>Type*</Form.Label>
              <Form.Select
                onChange={typeHandler}
                className="subAdmin-modal-input"
              >
                <option value="movie">Movies</option>
                <option value="web">Web Series</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col className="col-6 mb-3">
              <Form.Label>Start Date*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="date"
                name="startDate"
                required
                onChange={handleChange}
              />
            </Col>
            <Col className="col-6 mb-3">
              <Form.Label>End Date*</Form.Label>
              <Form.Control
                className="subAdmin-modal-input"
                type="date"
                name="endDate"
                required
                onChange={handleChange}
              />
            </Col>
          </Row>

          <button type="Submit" className="subAdmin-button">
            {laoding ? <LoadingPage /> : <span>Update</span>}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CronModal;
