import React from 'react'
import { Modal, Table } from 'react-bootstrap';

const SubAdminTabsModal = (props) => {
    const { show, handleClose, data } = props;
  return (
    <Modal className="subAdmin-modal" size='xs' centered show={show} onHide={handleClose}>
    <Modal.Header className="subAdmin-modal-header" closeButton>
      <Modal.Title>Tab Access</Modal.Title>
    </Modal.Header>
    <Modal.Body className="subAdmin-modal-body">
    <Table bordered>
      <thead className="subAdmin-tableHeader">
        <tr>
          <th>Tab Name</th>
        </tr>
      </thead>
      <tbody className="subAdmin-tableBody">
        {
          data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.label}</td>
              </tr>

            )
          })
        }
      </tbody>
    </Table>
    </Modal.Body>
  </Modal>
  )
}

export default SubAdminTabsModal;