import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import {
    deletePlatform,
  deleteTrending,
  getPlatforms,
  updateTrending,
} from "../../util/Auth/API";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState } from "react";
import DeleteModal from "../../util/Other/DeleteModal";
import { useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">||||</Handle>
));
const TableRow = ({
  index,
  no,
  property,
  className,
  setUpdate,
}) => {

      const handleDelete = async (id) => {
        const body = {
          id: id,
        };
        try {
          await deletePlatform(body);
          toast.success("Deleted Successfully");
          setUpdate(true);
        } catch (err) {
          console.log(err);
          toast.error("Something went wrong");
        }
      };


  return (
    <TrWrapper className={className}>
      <td>
        <div className="firstElement">
          <RowHandler />
          {no}
        </div>
      </td>
      <td className="image-td rounded">
        <img src={property?.logo} alt="logo" />
      </td>
      <td>{property.name}</td>
      <td className="text-center">
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            to={{
              pathname: `/platform/edit`,
              state: { data: property },
            }}
          >
            {" "}
            <button className="edit-btn">
              <ModeEditIcon />{" "}
            </button>
          </Link>
          <button
            className="delete-btn"
            onClick={() => handleDelete(property._id)}
          >
            <DeleteIcon />
          </button>
        </div>
      </td>
    </TrWrapper>
  );
};

export default TableRow;
