import React, { useState } from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addToCalendar,
  addToTheater,
  searchListById,
  searchListByName,
  updateCalendar,
  updateTheater,
} from "../../util/Auth/API";
import noMovie from "../../../src/assets/noImg.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ReactQuill from "react-quill";

const AddCalendar = () => {
  const [data, setData] = useState(null);
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [form, setform] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("name");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "video",
    "font",
  ];
  // const [date, setDate] = useState("");

  const action = (d, index) => {
    setSelected(index);
    console.log(d);
    const isMovie = d.movieId ? true : false;

    const isSeasonNumber = d.seasonNumber ? true : false;

    let data;
    if (isSeasonNumber) {
      if (d?.type === "Movie") {
        data = {
          isMovie: isMovie,
          movieId: d.movieId,
          streaming_date: new Date(Date.now()),
        };
      } else {
        data = {
          isMovie: isMovie,
          webSeriesId: d.webSeriesId,
          seasonNumber: d.seasonNumber,
          streaming_date: new Date(Date.now()),
        };
      }
    } else {
      if (d?.type === "Movie") {
        data = {
          isMovie: isMovie,
          movieId: d.movieId,

          streaming_date: new Date(Date.now()),
        };
      } else {
        data = {
          isMovie: isMovie,
          webSeriesId: d.webSeriesId,

          streaming_date: new Date(Date.now()),
        };
      }
    }

    setform(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("date: ", form.streaming_date);
      if (location.pathname !== "/theater/new") {
        const res = await addToCalendar({
          ...form,
          description: description,
        });
        if (res.status === 200) {
          setDescription("");
        }
      }
      if (location.pathname.includes("/theater/new")) {
        const res = await addToTheater(form);
        if (res.status === 200) {
          toast.success("Added to Theater");
        }
      } else if (!location.pathname.includes("/theater/edit")) {
        toast.success("Added to Calendar");
      }
    } catch (err) {
      console.log(err?.response);
      toast.error(err?.response?.data?.error || "Something went wrong");
      console.log(err);
    }
  };

  const changeSearchTypeHandler = (e) => {
    setSearchType(e.target.value);
    setQuery("");
  };

  const FetchData = async () => {
    setLoading(true);
    try {
      let res;
      if (searchType === "name") {
        res = await searchListByName(query);
      } else if (searchType === "id") {
        res = await searchListById(query);
      }
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="p-5 text-white">
      <div>
        <div className="d-flex">
          <Form.Select
            onChange={changeSearchTypeHandler}
            style={{ width: "100px", marginRight: "10px" }}
          >
            <option value="name">Title</option>
            <option value="id">TMDB ID</option>
          </Form.Select>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            onClick={() => FetchData(query)}
            style={{ backgroundColor: "#FF4F4F", border: "0", color: "white" }}
            variant="outline-success"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Search"
            )}
          </Button>
        </div>

        {data === null ? (
          ""
        ) : data.length !== 0 ? (
          <>
            <div
              style={{
                maxHeight: "60vh",
                marginTop: "30px",
                overflow: "auto",
              }}
            >
              <Table className="bg-white" hover size="sm">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Poster</th>
                    <th>Tmbd Id</th>
                    <th>Title</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, index) => (
                    <tr
                      key={index}
                      onClick={() => action(d, index)}
                      style={{
                        background: selected === index ? "#C0C0C0" : "white",
                        cursor: "pointer",
                      }}
                    >
                      <td className="align-middle">{index + 1}</td>
                      <td className="image-td">
                        <img
                          src={
                            d?.poster_path
                              ? `https://image.tmdb.org/t/p/original/${d.poster_path}`
                              : noMovie
                          }
                          alt="Poster"
                        />
                      </td>
                      <td className="align-middle">
                        {d.webSeriesId || d.movieId}
                      </td>
                      <td className="align-middle">
                        {d.original_name || d.name}
                      </td>
                      <td className="align-middle">{d.type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {location.pathname.includes("/calendar/new") && (
              <div className="d-flex my-3">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  width="100%"
                  style={{
                    width: "100%",
                    height: "200px",
                    backgroundColor: "#121212",
                  }}
                  formats={formats}
                  value={description}
                  onChange={(content, delta, source, editor) => {
                    setDescription(editor.getHTML());
                  }}
                />
              </div>
            )}

            <Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
              <Form.Label>Set Streaming Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) =>
                  setform({ ...form, streaming_date: e.target.value })
                }
              />
            </Form.Group>

            <Button
              style={{
                backgroundColor: "#FF4F4F",
                border: "0",
                marginTop: "20px",
              }}
              variant="primary"
              onClick={handleSubmit}
              disabled={selected === null ? true : false}
            >
              Add Selected
            </Button>
          </>
        ) : (
          <h5 className="text-center mt-5">Nothing found !!</h5>
        )}
      </div>
    </div>
  );
};

export default AddCalendar;
