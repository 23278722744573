import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteModal = ({ show, handleConfirm, handleCancel, categorytag }) => {
    
    return (
            <Modal
                show={show}
                onHide={handleCancel}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this {categorytag}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
    );
};

export default DeleteModal;
