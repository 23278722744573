import React, { useState } from "react";
import { useEffect } from "react";
import FilterRow from "./FilterRow";


const FilterTable = ({ data, setUpdate, setTrending, platform }) => {
  useEffect(() => {
    setAllProperty(data);
  }, [data]);

  const [allProperty, setAllProperty] = useState(data);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>Serial</th>
            <th>Poster</th>
            <th>Title</th>
            <th>Tagline</th>
            <th className="text-center">Select Platform</th>
            <th className="text-center">HomePage</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            allProperty?.map((value, index) => (
              <FilterRow
                key={`item-${index}`}
                index={index}
                property={value}
                no={index + 1}
                setUpdate={setUpdate}
                allProperty={allProperty}
                setTrending={setTrending}
                setAllProperty={setAllProperty}
                platform={platform}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilterTable;
