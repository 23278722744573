import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { addBanner } from "../../util/Auth/API";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { toast } from "react-hot-toast";

function AddNewBanner() {
  const [inputFields, setInputFields] = useState();

  const handleInputchange = (e) => {
    console.log(e);
    const { name, value } = e.target;

    setInputFields({ ...inputFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      ...inputFields,
    };

    try {
      await addBanner(body);
      toast.success("Updated Successfully");
      // setspinn(false);
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
      // setspinn(false);
    }
  };

  return (
    <div>
      <form>
        <div className="addproperty-container">
          <div className="addproperty-personalDetails">
            <div className="addproperty-alignRow">
              <div className="meal-title">
                <Link to="/masterdata">
                  <WestOutlinedIcon />
                </Link>
                <p>Back</p>
              </div>
            </div>
            {/* 1st row */}
            <div className="addproperty-alignRow">
              <div className="addproperty-inputFieldDiv form-group">
                <label className="addproperty-inputLabel ">
                  Text{" "}
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
                </label>
                <textarea
                  name="text"
                  defaultValue={inputFields?.text}
                  placeholder="Title"
                  rows={2}
                  className="addproperty-inputField"
                  onChange={handleInputchange}
                />
              </div>
              <div className="addproperty-inputFieldDiv form-group">
                <label className="addproperty-inputLabel ">
                  Background Url{" "}
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
                </label>
                <input
                  type="text"
                  name="background"
                  defaultValue={inputFields?.background}
                  placeholder="background url"
                  className="addproperty-inputField"
                  onChange={handleInputchange}
                />
              </div>
            </div>

            <div className="addproperty-submitDetailDiv">
              <button
                className="addproperty-submitDetailBtn"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewBanner;
